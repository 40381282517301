import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import Checkbox from 'common/components/form/controls/Checkbox';
import Input from 'common/components/form/controls/Input';

const User = ({ locale, ...props }) => {
    const locales = locale.Reports;
    const formValues = props.formProps.values;
    const isUserCriteriaEnabled = get(formValues, 'u.use');
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.User}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="u.use"
                    component={Checkbox}
                    label={`${locales.IncludeOnlyAlarmsSentBy}:`}
                />
                <Field
                    name="u.n"
                    type="text"
                    component={Input}
                    disabled={!isUserCriteriaEnabled}
                />
            </Grid>
        </Grid>
    );
};

User.propTypes = {
    formProps: PropTypes.object,
    locale: PropTypes.object.isRequired,
};

const exported = {
    component: User,
    key: 'user',
};

export default exported;
