import { get, post } from 'core/communcation/api';

export const getDevices = () => {
    return get('devices/devices');
};

export const getProfiles = (deviceId) => {
    const input = ['GetProfiles', 0];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const getRemainingLicenses = (deviceId) => {
    const input = ['GetRemainingLicenses', 0];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const verifyCode = (deviceId, code) => {
    const input = ['VerifyCode', code];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const addPhone = (deviceId, phoneDto) => {
    const input = ['AddClient', phoneDto];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const replacePhone = (deviceId, replacePhoneDto) => {
    const input = ['ReplaceClient', replacePhoneDto];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const deletePhone = (deviceId, phoneId) => {
    const input = ['DeleteClient', phoneId];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const getStatus = (deviceId) => {
    const input = ['GetStatus', 0];
    return post(`devices/devices/${deviceId}/actions`, input);
};

export const updateSmartphone = (deviceId, updatePhoneDto) => {
    const input = ['ModifyClient', updatePhoneDto];
    return post(`devices/devices/${deviceId}/actions`, input);
};
