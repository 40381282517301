const createAlarmIdsDataSource = (fields) => {
    if (!fields) {
        return [];
    }
    const alarmIds = fields.filter((field) => field.id === 'AlarmId');
    if (alarmIds === undefined || alarmIds.length === 0) {
        return [];
    }
    const aliases = alarmIds[0].aliases;
    const aliasesProperties = Object.getOwnPropertyNames(aliases);
    return aliasesProperties.map((item) => {
        const text = aliases[item];
        return { value: item, label: text };
    });
};

export default createAlarmIdsDataSource;
