import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentWirelessList = React.lazy(() =>
    import('./WirelessList'),
);

export default () => (
    <>
        <PageTitle pluginId="wireless" pluginNameTag="Wireless" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentWirelessList />
        </Suspense>
    </>
);
