import React from 'react';
import Routing from 'configuration/routing/routing';
import { StoreProvider } from 'core/state/StoreProvider';
import GlobalLayout from 'common/components/layout/GlobalLayout';

export default ({ theme }) => (
    <StoreProvider theme={theme}>
        <GlobalLayout>
            <Routing />
        </GlobalLayout>
    </StoreProvider>
);
