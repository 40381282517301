import UserActivity from 'common/components/user-activity/containers/UserActivityManager';
import { keepSessionAlive, redirectToLogin } from './sessionManagerService';

export default class SessionManagerStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    onDemandPoll = async () => {
        const isUserActive = UserActivity.isActive;
        const result = await keepSessionAlive(isUserActive);
        if (!result || !result.success) {
            redirectToLogin(true, this.rootStore.routerStore.state.pathname);
        }
    };
}
