import { patch } from 'core/communcation/api';
import { processNotification } from '../../common/helpers/webSockets/defaultNotificationProcessor';
import {
    mergeBufferedDataWithActivations,
    parseNotification,
} from '../../common/helpers/webSockets/notificationsHelper';
import { frameType } from '../../common/helpers/webSockets/webSocketConsts';

export const processAlarmNotification = (
    notification,
    currentDataState,
    isActivated,
) => processNotification(notification, currentDataState, isActivated);

export const mergeAlarmsBufferedDataWithActivations = (
    currentData,
    activationsData,
) => mergeBufferedDataWithActivations(currentData, activationsData);

export const parseAlarmNotification = (notification) =>
    parseNotification(notification);

export const getActivationsData = (notification) => {
    if (!isSuccessfulActivationResponse) {
        return [];
    }
    const notificationValue = notification[3];
    return {
        activations: notificationValue.activations.map((item) => {
            return { ...item, logIndex: notificationValue.logIndex };
        }),
        logIndex: notificationValue.logIndex,
    };
};

export const isSuccessfulActivationResponse = (notification) => {
    const isGetActivationsResponse =
        notification.length === 4 &&
        notification[0] === frameType.RESPONSE &&
        notification[1] === 668 &&
        notification[2] === 200 &&
        notification[3];
    return isGetActivationsResponse;
};

export const isSuccessfulSubscriptionResponse = (notification) => {
    const isSubscriptionResponse =
        notification.length >= 3 &&
        notification[0] === frameType.RESPONSE &&
        notification[1] === 666 &&
        notification[2] === 200;
    return isSubscriptionResponse;
};

export const isSuccessfulUnsubscribeResponse = (notification) => {
    const isUnsubscribeResponse =
        notification.length === 4 &&
        notification[0] === frameType.RESPONSE &&
        notification[1] === 667 &&
        notification[2] === 200;
    return isUnsubscribeResponse;
};

export const setActivationAccepted = (id) => {
    return patch(`alarms/activations/${id}`, { change: 'accept' });
};

export const setActivationRejected = (id) => {
    return patch(`alarms/activations/${id}`, { change: 'reject' });
};

export const setActivationClosed = (id) => {
    return patch(`alarms/activations/${id}`, { change: 'close' });
};
