import { action, observable, runInAction } from 'mobx';
import environmentUtils from 'common/utils/environmentUtils';
import createAlarmIdsDataSource from './../../../common/helpers/alarmIdDataSourceHelper';
import {
    addActionTag,
    getTagById,
    updateActionTag,
} from './../save/actionTagsSaveService';
import { deleteTag, fetchActionTags } from './actionTagsListService';

export default class ActionTagsListStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        deleteTagId: null,
        confirmationVisible: false,
        hasError: false,
        isReady: false,
        fetchedData: null,
        alarmIds: null,
        alarmIdsAliases: [],
        edit: {
            editedTag: null,
            editFormVisible: false,
        },
        add: {
            addFormVisible: false,
        },
    });

    get list() {
        if (!this.state.fetchedData) {
            return null;
        }
        const searchText =
            this.rootStore.actionTagsStore.actionTagsFiltersStore.state.text?.toLowerCase();
        return this.state.fetchedData.filter(
            (item) =>
                item.actionDetails?.toLowerCase()?.includes(searchText) ||
                item.id.toLowerCase()?.includes(searchText),
        );
    }

    fetchList = async () => {
        const response = await fetchActionTags();
        this.state.alarmIdsAliases = environmentUtils.getAlarmIdsAliases();
        if (response !== undefined && response.success) {
            const translatedData = this.prepareActionTagsData(response.data);
            runInAction(() => {
                this.state.fetchedData = translatedData;
                this.state.isReady = true;
                this.state.hasError = false;
            });
            return;
        }

        runInAction(() => {
            this.state.isReady = true;
            this.state.hasError = true;
        });
    };

    showRemoveConfirmation = action((tag) => {
        this.state.deleteTagId = tag.id;
        this.state.confirmationVisible = true;
    });

    rejectRemove = action(() => {
        this.state.confirmationVisible = false;
        this.state.deleteTagId = null;
    });

    confirmRemove = async () => {
        const deleteResult = await deleteTag(this.state.deleteTagId);
        if (deleteResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagRemoveSuccess',
                this.state.deleteTagId,
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagRemoveFailed',
                this.state.deleteTagId,
                'danger',
            );
        }
        this.rejectRemove();
        this.fetchList();
    };

    showAddForm = action(() => {
        this.state.add.addFormVisible = true;
    });

    edit = async (id) => {
        const tagDataResult = await getTagById(id);
        if (!tagDataResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagNotFound',
                id,
            );
            return;
        }
        if (tagDataResult && tagDataResult.data) {
            const requestedTag = tagDataResult.data;
            if (!this.state.alarmIds) {
                const fields = environmentUtils.getFields();
                const alarmIds = createAlarmIdsDataSource(fields);
                runInAction(() => {
                    this.state.alarmIds = alarmIds;
                });
            }
            runInAction(() => {
                this.state.edit.editedTag = requestedTag;
                this.state.edit.editFormVisible = true;
            });
        } else {
            this.fetchList();
            this.state.edit.editedTag = null;
            this.state.edit.editFormVisible = false;
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagNotFound',
                id,
            );
        }
    };

    cancelEdit = action(() => {
        this.state.edit.editedTag = null;
        this.state.edit.editFormVisible = false;
    });

    updateTag = async (formValues) => {
        const updateResult = await updateActionTag(formValues);
        const tagId = formValues.id;
        if (updateResult.success) {
            runInAction(() => {
                this.state.edit.editedTag = null;
                this.state.edit.editFormVisible = false;
            });
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagUpdateSuccess',
                tagId,
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagUpdateFail',
            );
        }
        this.fetchList();
    };

    addTag = async (formValues) => {
        const addResult = await addActionTag(formValues);
        if (addResult.success) {
            this.fetchList();
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagAddSuccess',
                formValues.id,
            );
            this.cancelAdd();
        } else {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagAddFail',
                formValues.id,
                'danger',
            );
        }
    };

    cancelAdd = action(() => {
        this.state.add.addFormVisible = false;
    });

    getAlarmIdDisplayValue = (alarmIdValue) => {
        return this.state.alarmIdsAliases[alarmIdValue] || alarmIdValue;
    };

    translateActionToDetails = (tag) => {
        const { action, actionType } = tag;
        if (actionType === 'ALARM') {
            return `${this.getAlarmIdDisplayValue(action.alarmFieldIdValue)}, ${
                action.alarmFieldTypeValue
            }`;
        }

        if (action.alarmId != null) {
            return this.getAlarmIdDisplayValue(action.alarmId);
        }
        if (action.userName != null) {
            return action.userName;
        }
        if (action.targetHeader != null) {
            return action.targetHeader;
        }
        return '(unsupported tag type)';
    };

    prepareActionTagsData = (actionTagsList) => {
        return actionTagsList.map((tag) => {
            const item = {
                actionDetails: this.translateActionToDetails(tag),
                actionType: tag.actionType,
                id: tag.id,
                isEditable: tag.actionType !== 'CHECKIN',
            };
            return item;
        });
    };
}
