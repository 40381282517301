import React from 'react';
import { Redirect } from '@reach/router';
import { getUserLoggedIn } from 'core/communcation/authTokenService';

const withAuthenticationCheck = (WrappedComponent) => {
    return class WithAuthentication extends React.Component {
        render() {
            if (!getUserLoggedIn()) {
                return <Redirect noThrow to="/" />;
            }
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withAuthenticationCheck;
