import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentSecurityConfiguration = React.lazy(() =>
    import('./SecurityConfiguration'),
);

export default () => (
    <>
        <PageTitle pluginId="users" pluginNameTag="Users" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentSecurityConfiguration />
        </Suspense>
    </>
);
