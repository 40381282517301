import React from 'react';

const routingParamsContext = React.createContext(null);

export const RoutingContextProvider = ({ routingParams, children }) => {
    return (
        <routingParamsContext.Provider value={routingParams}>
            {children}
        </routingParamsContext.Provider>
    );
};

export const useRouter = () => {
    const routingParams = React.useContext(routingParamsContext);
    if (!routingParams) {
        throw new Error('RoutingContextProvider is missing!');
    }
    return routingParams;
};
