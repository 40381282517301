import React from 'react';
import PropTypes from 'prop-types';
import ApplicationError from './ApplicationError';

export default class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false, info: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, info });
    }

    render() {
        if (this.state.hasError) {
            return <ApplicationError info={this.state.info.componentStack} />;
        }
        return this.props.children;
    }
}
