import baseRoutes from 'configuration/routing/baseRoutes';
import { action, observable, runInAction } from 'mobx';
import { redirect } from 'common/helpers/routerHelper';
import WebsocketMessageBuilder from './../../../common/websocket/websocketMessageBuilder';

export default class TwoFactorAuthStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        status: null,
        currentAuthenticationId: null,
    });

    getAuthenticationState = () => {
        this.rootStore.websocketStore.subscribe({
            name: '2fa',
            callback: (message) => {
                runInAction(() => {
                    if (!message || message.length < 3) {
                        return;
                    }
                    const type = message[0];
                    const messageId = message[1];

                    if (type === 'response') {
                        const status = message[2];

                        if (messageId === 1) {
                            this.state.status = message[3].status;
                            return;
                        }
                        if (messageId === 2 && status === 200) {
                            const data = message[3];
                            this.state.currentAuthenticationId =
                                data.authenticationId;
                            return;
                        }
                        return;
                    }

                    if (
                        type === 'notification' &&
                        messageId === 'pagerAppLoginFinished'
                    ) {
                        const notificationData = message[2];
                        if (
                            notificationData &&
                            notificationData.authenticationId ===
                                this.state.currentAuthenticationId &&
                            notificationData.isAuthenticated
                        ) {
                            redirect(baseRoutes.main);
                        }
                    }
                });
            },
        });

        const messageBuilder = new WebsocketMessageBuilder();
        const message = messageBuilder
            .ofType('request')
            .withId(1)
            .withName('getAuthenticationState')
            .withData(null)
            .build();

        this.rootStore.websocketStore.send(message);
    };

    initializeSecondFactorAuthentication = () => {
        const messageBuilder = new WebsocketMessageBuilder();
        const message = messageBuilder
            .ofType('request')
            .withId(2)
            .withName('requestPagerAppLogin')
            .withData(null)
            .build();
        this.rootStore.websocketStore.send(message);
    };

    setDefault = action(() => {
        this.state.currentAuthenticationId = null;
        this.state.status = null;
    });
}
