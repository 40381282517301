import React from 'react';
import baseRoutes from 'configuration/routing/baseRoutes';
import { useStore } from 'core/state/StoreProvider';
import { redirect } from 'common/helpers/routerHelper';
import ChangeOwnPasswordForm from '../components/ChangeOwnPasswordForm';
import { changeOwnPasswordForCurrentlyLoggedUser } from './../modules/userService';

const ExternalPasswordChangeManager = () => {
    const rootStore = useStore();
    const notificationsStore = rootStore.notificationsStore;

    const onChangeOwnPasswordConfirm = (formData) => {
        changeOwnPassword(formData.oldPassword, formData.newPassword);
        onChangePasswordCancel();
    };

    const onChangePasswordCancel = () => {
        redirect(baseRoutes.home);
    };

    const changeOwnPassword = async (oldPassword, newPassword) => {
        const result = await changeOwnPasswordForCurrentlyLoggedUser(
            oldPassword,
            newPassword,
        );
        if (!result.success) {
            notificationsStore.showNotification(null, result.error);
        }
    };

    return (
        <ChangeOwnPasswordForm
            onSave={onChangeOwnPasswordConfirm}
            onCancel={onChangePasswordCancel}
        />
    );
};

export default ExternalPasswordChangeManager;
