import * as api from 'core/communcation/api';
import {
    getAuthToken,
    removeAuthToken,
    setAuthToken,
    setUserLoggedIn,
} from 'core/communcation/authTokenService';

export const createUserSession = (username) => {
    removeAuthenticationToken();
    const params = { username: username };
    const loginEndpoint = 'sessions';
    return api.post(loginEndpoint, params);
};

export const authenticateWithPassword = (password) => {
    const authToken = getAuthToken();
    const params = { password: password };
    const authEndpoint = `sessions/${authToken}/authentication`;
    return api.put(authEndpoint, params);
};

export const logout = () => {
    const authToken = getAuthToken();
    if (authToken) {
        const authEndpoint = `sessions/${authToken}`;
        return api.del(authEndpoint);
    }
};

export const setLoggedIn = (state) => {
    setUserLoggedIn(state);
};

export const removeAuthenticationToken = removeAuthToken;
export const setAuthenticationToken = setAuthToken;
