import { action, observable, runInAction } from 'mobx';
import environmentUtils from 'common/utils/environmentUtils';
import { computeStatus } from '../../helpers/deviceStatusTranslator';
import createAlarmIdsDataSource from './../../../common/helpers/alarmIdDataSourceHelper';
import { createWirelessAlarmTypesDataSource } from './../../helpers/dataSourcesHelper';
import {
    addWirelessDevice,
    updateWirelessDevice,
} from './../save/wirelessService';
import {
    fetchWirelessDevices,
    removeWirelessDevice,
} from './wirelessListService';

export default class WirelessListStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        isReady: false,
        hasError: false,
        data: null,
        fetchedData: null,
        alarmTypesAliases: null,
        alarmIdsAliases: null,
        alarmIds: null,
        alarmTypes: null,
        confirmationDescriptor: '',
        deleteDeviceId: null,
        edit: {
            editedDevice: null,
            editFormVisible: false,
        },
        add: {
            addFormVisible: false,
        },
    });

    fetchList = async () => {
        const [devicesResult] = await Promise.all([fetchWirelessDevices()]);
        if (devicesResult !== undefined && devicesResult.success) {
            const alarmTypesAliases = environmentUtils.getAlarmTypesAliases();
            const alarmIdsAliases = environmentUtils.getAlarmIdsAliases();
            const devices = devicesResult.data;
            runInAction(() => {
                this.state.fetchedData = devices;
                this.state.alarmTypesAliases = alarmTypesAliases;
                this.state.alarmIdsAliases = alarmIdsAliases;
                this.state.isReady = true;
            });
            return;
        }
        runInAction(() => {
            this.state.isReady = true;
            this.state.hasError = true;
        });
    };

    get list() {
        if (!this.state.fetchedData) {
            return null;
        }
        const searchText =
            this.rootStore.wirelessStore.wirelessFiltersStore.state.text;
        const options =
            this.rootStore.wirelessStore.wirelessFiltersStore.state.options;

        const getAlarmIdDisplayValue = (wirelessItem) =>
            this.state.alarmIdsAliases[wirelessItem.alarmId] ||
            wirelessItem.alarmId;

        return this.state.fetchedData
            .filter(
                (item) =>
                    getAlarmIdDisplayValue(item)
                        ?.toLowerCase()
                        .includes(searchText.toLowerCase()) &&
                    options.indexOf(
                        computeStatus(item.isBatteryOk, item.isMissing),
                    ) > -1,
            )
            .map((item) => {
                return {
                    ...item,
                    alarmId: getAlarmIdDisplayValue(item),
                    defaultFieldTypeValue:
                        this.state.alarmTypesAliases[
                            item.defaultFieldTypeValue
                        ] || item.defaultFieldTypeValue,
                };
            });
    }

    remove = action((device) => {
        this.state.deleteDeviceId = device.id;
        this.state.confirmationDescriptor = device.id;
        this.state.confirmationVisible = true;
    });

    confirmRemove = async () => {
        const deviceId = this.state.deleteDeviceId;
        const deleteResult = await removeWirelessDevice(deviceId);
        if (deleteResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceRemoveSuccess',
                deviceId,
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceRemoveFailed',
                deviceId,
            );
        }
        this.rejectRemove();
        this.fetchList();
    };

    rejectRemove = action(() => {
        this.state.deleteDeviceId = null;
        this.state.confirmationDescriptor = null;
        this.state.confirmationVisible = false;
    });

    edit = action(async (id) => {
        const requestedDevice = this.state.fetchedData.find(
            (item) => item.id === id,
        );
        if (requestedDevice) {
            if (!this.state.alarmIds || !this.state.alarmTypes) {
                const fields = environmentUtils.getFields();
                const alarmTypes = environmentUtils.getAlarmTypes();
                const alarmTypesAliases =
                    environmentUtils.getAlarmTypesAliases();
                const alarmIds = createAlarmIdsDataSource(fields);
                const wirelessAlarmTypes = createWirelessAlarmTypesDataSource(
                    alarmTypes.filter((item) => item.isAvailableForWireless),
                ).map((item) => {
                    return {
                        value: item.value,
                        label: alarmTypesAliases[item.label] || item.label,
                    };
                });
                runInAction(() => {
                    this.state.alarmTypesAliases = alarmTypesAliases;
                    this.state.alarmIds = alarmIds;
                    this.state.alarmTypes = wirelessAlarmTypes;
                });
            }
            runInAction(() => {
                this.state.edit.editedDevice = requestedDevice;
                this.state.edit.editFormVisible = true;
            });
        } else {
            this.fetchList();
            this.state.edit.editedDevice = null;
            this.state.edit.editFormVisible = false;
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceNotFound',
                id,
            );
        }
    });

    updateWirelessDevice = async (formValues) => {
        const updateResult = await updateWirelessDevice(formValues);
        if (updateResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceUpdateSuccess',
                formValues.id,
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceUpdateFail',
                formValues.id,
            );
        }
        this.fetchList();
        this.cancelEdit();
    };

    cancelEdit = action(() => {
        this.state.edit.editedDevice = null;
        this.state.edit.editFormVisible = false;
    });

    showAddForm = action(() => {
        this.state.add.addFormVisible = true;
    });

    cancelAdd = action(() => {
        this.state.add.addFormVisible = false;
    });

    addWireless = async (formValues) => {
        const addResult = await addWirelessDevice(formValues);
        if (addResult.success) {
            this.fetchList();
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceAddSuccess',
                formValues.transmitterId,
            );
            this.cancelAdd();
        } else {
            this.rootStore.notificationsStore.showNotification(
                'Wireless.DeviceAddFail',
                formValues.transmitterId,
            );
        }
    };
}
