import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import MultiSelector from 'common/components/multi-selector/MultiSelector';

const FieldInclusion = (props) => {
    const fieldName = 'fi.fn';
    const { formProps, locale } = props;
    const [fields, setFields] = useState([]);
    const initialValue = formProps.values.fi.fn;
    const [manualSelectEnabled, setManualSelectEnabled] = useState(
        initialValue && initialValue.length > 0,
    );

    useEffect(() => {
        const internalOptions = props.options.fields.map((item) => {
            const { label, value } = item;
            return {
                header: label,
                id: value,
            };
        });
        setFields(internalOptions);
    }, [props.options.fields]);

    useEffect(() => {
        if (manualSelectEnabled) return;
        formProps.setFieldValue(fieldName, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualSelectEnabled]);

    const handleSelection = (fieldName, selection) => {
        const formProps = props.formProps;
        formProps.setFieldValue(
            fieldName,
            selection.map((item) => item.id),
        );
    };

    const handleManualSelectChange = () => {
        setManualSelectEnabled((manualSelectEnabled) => !manualSelectEnabled);
    };

    const getValue = () => {
        const formProps = props.formProps;
        if (!fields) {
            return [];
        }
        const rawValues = get(formProps.values, fieldName);
        const value = rawValues?.map((item) => {
            return fields.find((option) => option.id === item);
        });
        return value || [];
    };

    const locales = locale.Reports;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.AlarmField}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={manualSelectEnabled}
                            onChange={handleManualSelectChange}
                        />
                    }
                    label={locales.ManuallySelectFields}
                />
                {manualSelectEnabled && (
                    <>
                        <Typography variant="subtitle1">
                            {locales.IncludeAlarmFields}
                        </Typography>
                        <MultiSelector
                            name={fieldName}
                            value={getValue()}
                            onChange={handleSelection}
                            options={fields}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

FieldInclusion.propTypes = {
    locale: PropTypes.object.isRequired,
    options: PropTypes.object,
    formProps: PropTypes.object,
};

const exported = {
    component: FieldInclusion,
    key: 'fieldInclusion',
};

export default exported;
