import {
    frameType,
    requestId,
    subscription,
} from '../webSockets/webSocketConsts';

export const isNotificationValidMessage = (notification) => {
    if (!notification) {
        return false;
    }
    if (notification.length < 3) {
        return false;
    }
    const isKeepAlive =
        notification[0] === frameType.RESPONSE &&
        notification[1] === requestId.KEEP_ALIVE;
    if (isKeepAlive) {
        return false;
    }

    const isMessage =
        notification.length === 3 &&
        notification[0] === frameType.NOTIFICATION &&
        notification[1] === subscription &&
        notification[2];

    if (!isMessage) {
        return false;
    }

    return true;
};
