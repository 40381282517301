import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponent = React.lazy(() => import('./Home'));

export default () => (
    <>
        <PageTitle pluginId="home" pluginNameTag="Home" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponent />
        </Suspense>
    </>
);
