import { observable, runInAction } from 'mobx';
import { getConnectionInfoData, getVersionInfo } from './connectionInfoService';

export default class LicenseInfoStore {
    constructor(rootStore, theme) {
        this.rootStore = rootStore;
        this.theme = theme;
    }
    state = observable({
        isReady: false,
        hasError: false,
        data: {
            connectionInfo: null,
            versionInfo: null,
            name: null,
            copyright: null,
        },
    });

    getLicenseInfo = async () => {
        if (this.state.data.connectionInfo) {
            return;
        }
        const connectionInfoResult = await getConnectionInfoData();
        const versionResult = await getVersionInfo();
        let versionInfo = '';

        if (versionResult) {
            versionInfo = versionResult;
        }

        if (connectionInfoResult.success) {
            runInAction(() => {
                this.state.data.connectionInfo = connectionInfoResult.data;
                this.state.data.versionInfo = versionInfo;
                this.state.data.name = this.theme.name;
                this.state.data.copyright = this.theme.copyright;
                this.state.isReady = true;
            });
            return;
        }
        this.rootStore.notificationsStore.showNotification(
            'Home.LicenseError',
            null,
            'danger',
        );
    };
}
