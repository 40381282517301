import baseRoutes from 'configuration/routing/baseRoutes';
import * as api from 'core/communcation/api';
import { redirect } from 'common/helpers/routerHelper';

export const fetchActionTags = () => {
    const actionTagsEndPoint = 'actiontags/tags';
    return api.get(actionTagsEndPoint);
};

export const deleteTag = (tagId) => {
    const actionTagDeleteEndPoint = `actiontags/tags/${encodeURIComponent(
        tagId,
    )}`;
    return api.del(actionTagDeleteEndPoint);
};

export const redirectToEdit = (tagId) =>
    redirect(`${baseRoutes.actionTag}${tagId}`);
