import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';

const RadioButton = ({ field, form, ...props }) => {
    const current = field.name;
    const fieldValue = props.value;
    const currentValue = get(form.values, current);
    return (
        <FormControlLabel
            {...props}
            {...field}
            checked={fieldValue === currentValue}
            value={fieldValue || ''}
            control={<Radio />}
        />
    );
};

RadioButton.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    value: PropTypes.string,
};

export default RadioButton;
