import baseRoutes from 'configuration/routing/baseRoutes';
import { action, observable, runInAction } from 'mobx';
import { redirect } from 'common/helpers/routerHelper';
import environmentUtils from 'common/utils/environmentUtils';
import { ActionType } from './../../helpers/enums';
import { getLastScanTime, scanForTags } from './actionTagsSaveService';

export default class ActionTagsAddStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    interval = null;

    state = observable({
        scanning: true,
        currentActionType: ActionType.Presence,
        tagId: null,
        fields: null,
        lastTagScanTime: null,
        isReady: false,
        hasError: false,
        isKnown: false,
        reprogrammingInProgress: false,
    });

    startScanning = action(async () => {
        this.state.scanning = true;
        this.state.tagId = null;
        const lastScanTime = await getLastScanTime();
        runInAction(() => {
            this.state.lastTagScanTime = lastScanTime;
        });

        this.interval = setInterval(async () => {
            const scanResult = await scanForTags(this.state.lastTagScanTime);
            if (!scanResult.success) {
                clearInterval(this.interval);
                this.rootStore.notificationsStore.showNotification(
                    'ActionTags.TagAddFail',
                );
                redirect(`/${baseRoutes.main}/${baseRoutes.actionTags}`);
            } else {
                if (scanResult.tagFound) {
                    clearInterval(this.interval);
                    runInAction(() => {
                        this.state.scanning = false;
                        this.state.isKnown = scanResult.isKnown;
                    });

                    runInAction(() => {
                        this.state.fields = environmentUtils.getFields();
                        this.state.tagId = scanResult.tagId;
                    });
                }
            }
        }, 1000);
    });

    stopScanning = action(() => {
        this.state.scanning = false;
        clearInterval(this.interval);
    });

    cancel = action(() => {
        this.state.isKnown = false;
        this.state.reprogrammingInProgress = false;
        this.state.tagId = null;
    });

    confirmReprogramming = action(() => {
        this.state.reprogrammingInProgress = true;
        this.state.isKnown = false;
    });
}
