import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import MultiSelector from 'common/components/multi-selector/MultiSelector';

const SecurityUser = ({ options, locale, formProps }) => {
    const [currentValues, setCurrentValues] = useState([]);
    const locales = locale.Reports;
    let securityUsersOptions = options.securityUsers.map((item) => {
        return {
            header: item.name,
            id: item.name,
        };
    });
    securityUsersOptions = [
        { header: locale.Common.All, id: null },
        ...securityUsersOptions,
    ];

    const fieldName = 'su.ns';

    const handleOnChange = (fieldName, selection, lastValue) => {
        let values = selection;
        if (lastValue.id == null) {
            values = [{ header: locale.Common.All, id: null }];
        } else {
            values = values.filter((item) => item.id != null);
        }
        setCurrentValues(values);

        formProps.setFieldValue(
            fieldName,
            values.map((v) => v.id),
        );
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.User}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {locales.IncludeOnlyActionAssignedToUser}
                </Typography>
                <MultiSelector
                    name={fieldName}
                    value={currentValues}
                    onChange={handleOnChange}
                    options={securityUsersOptions}
                />
            </Grid>
        </Grid>
    );
};

SecurityUser.propTypes = {
    locale: PropTypes.object.isRequired,
    options: PropTypes.object,
    formProps: PropTypes.object,
};

const exported = {
    component: SecurityUser,
    key: 'securityUser',
};

export default exported;
