import { action, observable } from 'mobx';
import { updateOptions } from 'common/helpers/filterOptionsHelper';
import { ReportStatus } from './../../helpers/enums';

export default class RequestedFiltersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        options: [
            ReportStatus.Generated,
            ReportStatus.Pending,
            ReportStatus.Failed,
        ],
        text: '',
    });

    textChanged = action((value) => {
        this.state.text = value;
    });

    optionChanged = action((value) => {
        this.state.options = updateOptions(this.state.options, value);
    });

    get reportsCount() {
        const count = {
            failedCount: 0,
            generatedCount: 0,
            waitingCount: 0,
        };
        const reports =
            this.rootStore.reportsStore.requestedStore.state.rawData;
        if (!reports || reports.length === 0) {
            return count;
        }
        // eslint-disable-next-line no-unused-vars
        for (const item of reports) {
            if (item.status === ReportStatus.Failed) {
                count.failedCount++;
            }
            if (item.status === ReportStatus.Generated) {
                count.generatedCount++;
            }
            if (item.status === ReportStatus.Pending) {
                count.waitingCount++;
            }
        }
        return count;
    }

    get selection() {
        const contains = (status) => this.state.options.indexOf(status) > -1;
        const selection = {
            isFailedSelected: contains(ReportStatus.Failed),
            isGeneratedSelected: contains(ReportStatus.Generated),
            isWaitingSelected: contains(ReportStatus.Pending),
        };
        return selection;
    }
}
