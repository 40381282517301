import React from 'react';
import createRootStore from 'configuration/rootStore';
import { useLocalObservable } from 'mobx-react-lite';

const storeContext = React.createContext(null);

export const StoreProvider = ({ children, theme }) => {
    const store = useLocalObservable(() => createRootStore(theme));
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error('StoreProvider is missing!');
    }
    return store;
};
