import { action, observable } from 'mobx';
import { updateOptions } from 'common/helpers/filterOptionsHelper';
import { computeStatus } from '../../helpers/deviceStatusTranslator';
import { DeviceStatus } from './../../helpers/enums';

export default class WirelessFiltersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        options: [
            DeviceStatus.NoIssues,
            DeviceStatus.Warning,
            DeviceStatus.Danger,
        ],
        text: '',
    });

    textChanged = action((value) => {
        this.state.text = value;
    });

    optionChanged = action((value) => {
        this.state.options = updateOptions(this.state.options, value);
    });

    get selection() {
        const contains = (status) => this.state.options.indexOf(status) > -1;
        const selection = {
            isNoIssuesSelected: contains(DeviceStatus.NoIssues),
            isWarningSelected: contains(DeviceStatus.Warning),
            isDangerSelected: contains(DeviceStatus.Danger),
        };
        return selection;
    }

    get devicesCount() {
        const count = {
            noIssuesCount: 0,
            warningCount: 0,
            dangerCount: 0,
        };
        const devices =
            this.rootStore.wirelessStore.wirelessListStore.state.fetchedData;
        if (!devices) {
            return count;
        }
        // eslint-disable-next-line no-unused-vars
        for (const item of devices) {
            const computedStatus = computeStatus(
                item.isBatteryOk,
                item.isMissing,
            );

            if (computedStatus === DeviceStatus.NoIssues) {
                count.noIssuesCount++;
            }
            if (computedStatus === DeviceStatus.Warning) {
                count.warningCount++;
            }
            if (computedStatus === DeviceStatus.Danger) {
                count.dangerCount++;
            }
        }
        return count;
    }
}
