import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

class TargetSearch extends React.Component {
    static propTypes = {
        onSearch: PropTypes.func.isRequired,
    };

    state = {
        text: '',
    };

    handleChange = (event) => {
        this.setState({ text: event.target.value });
    };

    onSearch = () => {
        this.props.onSearch(this.state.text);
    };

    render() {
        return (
            <Grid container alignItems="flex-end" spacing={1}>
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        label="Search..."
                        value={this.state.text}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        type="button"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onClick={this.onSearch}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default TargetSearch;
