import { action, autorun, observable } from 'mobx';

export default class RouterStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        autorun(() => {
            if (this.state.pathname === '/') {
                this.rootStore.loginStore.logout();
            }
        });
    }

    state = observable({
        pathname: window.location.pathname,
        action: 'PUSH',
    });

    setLocation = action((pathname, action) => {
        this.state.pathname = pathname;
        this.state.action = action;
    });
}
