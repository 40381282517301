import { observable, runInAction, when } from 'mobx';
import { frameType } from 'scenes/main/common/helpers/webSockets/webSocketConsts';
import environmentUtils from 'common/utils/environmentUtils';
import toHex from '../../common/helpers/alarmColorParser';
import messages from '../alarmLogMessages';
import { fetchAlarmLogs, getMessageData } from './alarmLogService';

export default class AlarmLogStore {
    websocketSubscriberName = 'alarm-log';

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    visibleFieldsIds = null;
    normalizedFields = null;

    state = observable({
        isReady: false,
        hasError: false,
        data: null,
        isSubscribed: false,
    });

    alarmFields = [];

    initialize = async () => {
        const alarmLogs = await fetchAlarmLogs();
        const alarmFields = environmentUtils.getFields();
        if (alarmLogs.success && alarmFields && alarmLogs.data) {
            const { visibleFieldIds, visibleFieldsNormalized, alarmLogData } =
                this.prepareAlarmLogData(alarmLogs.data, alarmFields);
            this.alarmFields = alarmFields;

            this.visibleFieldsIds = visibleFieldIds;
            this.normalizedFields = visibleFieldsNormalized;

            runInAction(() => {
                this.state.data = alarmLogData;
                this.state.isReady = true;
                this.state.hasError = false;
            });
        } else {
            runInAction(() => {
                this.state.isReady = true;
                this.state.hasError = true;
            });
        }
    };

    subscribe = () => {
        when(
            () => this.rootStore.websocketStore.state.connectionOpened,
            this.createMessageHandler,
        );
    };

    createMessageHandler = () => {
        this.rootStore.websocketStore.subscribe({
            name: this.websocketSubscriberName,
            callback: (message) => {
                if (message[0] === frameType.RESPONSE && message[1] === 778) {
                    return;
                }
                const updatedData = getMessageData(message);
                if (!updatedData || updatedData.length === 0) {
                    return;
                }
                const currentData = this.state.data;
                const { alarmLogData } = this.prepareAlarmLogData(
                    updatedData,
                    this.alarmFields,
                );
                runInAction(() => {
                    this.state.data = [...alarmLogData, ...currentData];
                });
            },
        });
        this.rootStore.websocketStore.send(messages.subscribe);
    };

    unsubscribe = () => {
        this.rootStore.websocketStore.send(messages.unsubscribe);
        this.rootStore.websocketStore.unsubscribe(this.websocketSubscriberName);
        runInAction(() => {
            this.state.isReady = false;
            this.state.data = null;
            this.state.visibleFields = null;
            this.state.isSubscribed = false;
        });
    };

    prepareAlarmLogData = (data, fields) => {
        const fieldsVisibleInLog = this.getVisibleFields(fields);
        const fieldIds = fieldsVisibleInLog.map((field) => field.id);
        const normalizedFields = this.getNormalizedFields(fieldsVisibleInLog);
        const alarmLogData = data.map((item) =>
            this.buildAlarmLogWithFields(item, fieldIds, normalizedFields),
        );
        return {
            visibleFieldIds: fieldIds,
            visibleFieldsNormalized: normalizedFields,
            alarmLogData,
        };
    };

    getVisibleFields = (allFields) => {
        return allFields.filter((field) => field.isVisibleInLog);
    };

    getNormalizedFields = (rawFields) => {
        const normalizedFields = {};
        for (const field of rawFields) {
            const fieldId = field.id;
            const fieldName = fieldId[0].toLowerCase() + fieldId.slice(1);
            normalizedFields[fieldId] = {
                header: field.header,
                name: fieldName,
                alias: `${fieldName}Alias`,
                value: `${fieldName}Value`,
            };
        }
        return normalizedFields;
    };

    buildAlarmLogWithFields = (item, fieldIds, normalizedFields) => {
        const alarmLog = {
            alarmDateTime: new Date(item.alarmDateTime).toLocaleString(),
            color: toHex(item.color),
            id: item.id,
        };

        for (const id of fieldIds) {
            const { name, alias, value } = normalizedFields[id];
            const alarmFieldValue =
                item[alias] == null ? item[value] : item[alias];
            alarmLog[name] = alarmFieldValue;
        }

        return alarmLog;
    };
}
