import LoginStore from 'scenes/login/modules/loginStore';
import TwoFactorAuthStore from 'scenes/login/modules/twoFactorAuthStore';
import ActionTagsStore from 'scenes/main/action-tags/actionTagsStore';
import ActiveAlarmsStore from 'scenes/main/active-alarms/modules/activeAlarmsStore';
import AlarmLogStore from 'scenes/main/alarm-log/modules/alarmLogStore';
import LicenseInfoStore from 'scenes/main/home/modules/licenseInfoStore';
import ReportsStore from 'scenes/main/reports/reportsStore';
import DevicesStore from 'scenes/main/smartphones/modules/devices/devicesStore';
import SmartphonesStore from 'scenes/main/smartphones/modules/smartphones/smartphonesStore';
import WirelessStore from 'scenes/main/wireless/wirelessStore';
import EnvironmentStore from 'core/environment/environmentStore';
import LocaleStore from 'core/locale/localeStore';
import NavigationStore from 'common/components/navigation/modules/navigationStore';
import NotificationsStore from 'common/components/notifications/stores/notificationsStore';
import SessionManagerStore from 'common/components/session-manager/modules/sessionManagerStore';
import ThemeStore from 'common/components/theme/modules/themeStore';
import ReferenceDataStore from 'common/modules/reference-data/referenceDataStore';
import WebsocketStore from 'common/websocket/websocketStore';
import RouterStore from './routing/routerStore';

class RootStore {
    constructor(theme) {
        this.environmentStore = new EnvironmentStore();
        this.localesStore = new LocaleStore();
        this.themeStore = new ThemeStore(this, theme);
        this.notificationsStore = new NotificationsStore(this);
        this.loginStore = new LoginStore(this);
        this.licenseInfoStore = new LicenseInfoStore(this, theme);
        this.alarmLogStore = new AlarmLogStore(this);
        this.navigationStore = new NavigationStore(this);
        this.referenceDataStore = new ReferenceDataStore();
        this.wirelessStore = new WirelessStore(this);
        this.actionTagsStore = new ActionTagsStore(this);
        this.reportsStore = new ReportsStore(this);
        this.websocketStore = new WebsocketStore(this);
        this.twoFactorAuthStore = new TwoFactorAuthStore(this);
        this.activeAlarmsStore = new ActiveAlarmsStore(this);
        this.routerStore = new RouterStore(this);
        this.sessionManagerStore = new SessionManagerStore(this);
        this.devicesStore = new DevicesStore(this);
        this.smartphonesStore = new SmartphonesStore(this);
    }
}

function createRootStore(theme) {
    const rootStore = new RootStore(theme);
    return { ...rootStore };
}

export default createRootStore;
