export const ReportStatus = {
    Generated: 'Generated',
    Pending: 'Pending',
    Failed: 'FailedToGenerate',
};

export const TemplateType = {
    Custom: '0',
    BuiltIn: '1',
};

export const CreateType = {
    Template: '0',
    Report: '1',
};

export const ReportClassId = {
    AlarmDuration: 'AlarmDuration',
    AlarmFrequencyDay: 'AlarmFrequency',
    AlarmFrequencyWeek: 'AlarmFrequencyWeek',
    Attendance: 'Attendance',
    ExcessiveResponseTime: 'ExcessiveResponseTime',
    ListOfActions: 'ListOfActions',
    ListOfAlarms: 'ListOfAlarms',
    ListOfOperationalAlarms: 'ListOfOperationalAlarms',
    NumberOfAlarmsPerId: 'NumberOfAlarmsPerId',
    NumberOfAlarmsPerType: 'NumberOfAlarmsPerType',
    NumberOfCarers: 'Attendants',
    NumberOfCarersPlanning: 'AttendantsPlanning',
    PlannedVsActualActions: 'PlannedVsActualActions',
    PresenceByGender: 'PresenceByGender',
    ResponseTimeAndTimeOfCareGiven: 'ResponseAndCareTime',
    ResponseTimeAndTimeOfCareGivenPerUser: 'ResponseAndCareTimePerUser',
    ScheduledActions: 'ScheduledActions',
    TimeToResponse: 'TimeToResponse',
    TimeTracking: 'TimeTracking',
    TravelTime: 'TravelTime',
    UserWorkload: 'UserWorkload',
    UserWorkloadPlanning: 'UserWorkloadPlanning',
    AlarmHistory: 'AlarmHistory',
    PagerReport: 'PagerReport',
    Accessory: 'Accessory',
    Security: 'Security',
};

export const DateRangeMode = {
    Absolute: '0',
    Last7Days: '1',
    Last30Days: '2',
    LastNDays: '3',
    LastMonth: '4',
    LastWeek: '5',
    Yesterday: '6',
};

export const SliceRangeMode = {
    Unspecified: '0',
    Absolute: '1',
    LastNSlices: '2',
    LastMonth: '3',
};
