import { del, get, post, put } from 'core/communcation/api';

export const addNewUser = async (data) => {
    return await post('security/users', data);
};

export const deleteUserByName = async (userName) => {
    return await del(`security/users/${userName}`);
};

export const changePasswordForOtherUser = async (userName, newPassword) => {
    return await put(`security/authentication/${userName}`, {
        newPassword: newPassword,
    });
};

export const changeOwnPasswordForCurrentlyLoggedUser = async (
    oldPassword,
    newPassword,
) => {
    return await put('security/authentication', { oldPassword, newPassword });
};

export const updateUserGroupsAssignment = async (userId, groups) => {
    return await put(`security/users/${encodeURIComponent(userId)}`, {
        updateGroups: true,
        newGroups: groups,
    });
};

export const getRandomPassword = async (userName) => {
    return await get(`security/password_new?userName=${userName}`);
};

export const validatePassword = async (password, userName) => {
    return await post(`security/password_compliancecheck`, {
        password,
        userName,
    });
};
