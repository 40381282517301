import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentRequested = React.lazy(() => import('./Requested'));

const LoadableComponentTemplates = React.lazy(() => import('./Templates'));

export const LoadableRequested = () => (
    <>
        <PageTitle pluginId="reports" pluginNameTag="Reports" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentRequested />
        </Suspense>
    </>
);

export const LoadableTemplates = () => (
    <>
        <PageTitle pluginId="reports" pluginNameTag="Reports" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentTemplates />
        </Suspense>
    </>
);
