import * as api from 'core/communcation/api';

export const fetchWirelessDevices = () => {
    const wirelessListEndPoint = 'wirelessdevices/devices';
    return api.get(wirelessListEndPoint);
};

export const removeWirelessDevice = (deviceId) => {
    const deleteDeviceEndPoint = `wirelessdevices/devices/${encodeURIComponent(
        deviceId,
    )}`;
    return api.del(deleteDeviceEndPoint);
};
