import React, { useEffect, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withLocale from 'common/hoc/withLocale';

const AlarmIdSelector = ({
    rawValue,
    valueByType,
    alarmIds,
    onChange,
    locale,
}) => {
    const [selection, setSelection] = useState([]);
    const [inputValue, setInputValue] = useState(valueByType ? '' : rawValue);
    const [shouldCreateCombinedValue, setShouldCreateCombinedValue] =
        useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuWidth, setMenuWidth] = useState('auto');
    const open = Boolean(anchorEl);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!valueByType) return;
        setInputValue(valueByType.input);
        setSelection(valueByType.selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!shouldCreateCombinedValue) return;
        setShouldCreateCombinedValue(false);
        setCombinedValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldCreateCombinedValue]);

    const setCombinedValue = () => {
        onChange(inputValue, selection);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setShouldCreateCombinedValue(true);
    };

    const handleSelect = (value, label) => {
        setSelection((selection) => [...selection, { value, label }]);
        setShouldCreateCombinedValue(true);
    };

    const handleRemove = (value) => {
        const newSelection = selection.filter((item) => item.value !== value);
        setSelection(newSelection);
        setShouldCreateCombinedValue(true);
    };

    const handleShowList = () => {
        const controlWrapper = inputRef.current;
        setAnchorEl(controlWrapper);
        setMenuWidth(controlWrapper.clientWidth);
    };

    const handleHideList = () => {
        setAnchorEl(null);
    };

    return (
        <FormControl margin="normal" fullWidth>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {locale.Reports.AlarmId}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderBottom: '1px solid',
                    borderBottomColor: 'text.secondary',
                }}
                ref={inputRef}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {selection.map((item) => {
                            const { value, label } = item;
                            return (
                                <Chip
                                    key={value}
                                    variant="outlined"
                                    label={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p>{label}</p>
                                            <Box
                                                sx={{
                                                    opacity: 0.7,
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                <em>{value}</em>
                                            </Box>
                                        </Box>
                                    }
                                    onDelete={() => handleRemove(value)}
                                />
                            );
                        })}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Input
                            disableUnderline
                            fullWidth
                            placeholder={
                                locale.Reports.EnterAlarmIdManuallyPlaceholder
                            }
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                    onClick={handleShowList}
                >
                    <ArrowDropDownIcon />
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleHideList}
                    PaperProps={{
                        style: {
                            width: menuWidth,
                        },
                    }}
                >
                    {alarmIds?.map((i) => {
                        const { value, label } = i;
                        const existingItem = !!selection.find(
                            (item) => item.value === value,
                        );
                        return (
                            <MenuItem
                                key={value}
                                value={value}
                                onClick={() => handleSelect(value, label)}
                                disabled={existingItem}
                            >
                                {label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Box>
        </FormControl>
    );
};

export default withLocale(AlarmIdSelector);
