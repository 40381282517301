import baseRoutes from 'configuration/routing/baseRoutes';
import * as api from 'core/communcation/api';
import { getAuthToken } from 'core/communcation/authTokenService';
import { redirect } from 'common/helpers/routerHelper';

export const keepSessionAlive = (isUserActive = false) => {
    const session = getAuthToken();
    if (session) {
        const keepAliveEndpoint = `sessions/${session}/keepalive`;
        return api.put(keepAliveEndpoint, { isUserActive });
    }
    return null;
};

export const redirectToLogin = (wasOnDemand, returnUrl = baseRoutes.index) => {
    redirect(`${returnUrl}${wasOnDemand ? '' : '?u=1'}`);
};
