import { action, observable, runInAction } from 'mobx';
import environmentUtils from 'common/utils/environmentUtils';
import createAlarmIdsDataSource from './../../../common/helpers/alarmIdDataSourceHelper';
import { createWirelessAlarmTypesDataSource } from './../../helpers/dataSourcesHelper';
import { getLastScanTime, scanForWirelessDevices } from './wirelessService';

export default class WirelessAddStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    interval = null;

    state = observable({
        scanning: true,
        lastTagScanTime: null,
        isReady: false,
        hasError: false,
        alarmIds: null,
        alarmTypes: null,
        transmitterData: null,
        isKnown: false,
        knownFieldIdAlias: '',
        knownFieldIdValue: '',
        reprogrammingInProgress: false,
    });

    startScanning = action(async () => {
        this.state.scanning = true;
        const lastScanTime = await getLastScanTime();
        runInAction(() => {
            this.state.lastTagScanTime = lastScanTime;
        });

        this.interval = setInterval(async () => {
            const scanResult = await scanForWirelessDevices(
                this.state.lastTagScanTime,
            );
            if (!scanResult.success) {
                clearInterval(this.interval);
                this.rootStore.notificationsStore.showNotification(
                    'Wireless.DeviceAddFail',
                );
            } else {
                if (scanResult.deviceFound) {
                    clearInterval(this.interval);
                    const alarmTypes = environmentUtils.getAlarmTypes();
                    const fields = environmentUtils.getFields();
                    const alarmIds = createAlarmIdsDataSource(fields);
                    const alarmTypesAliases = fields[1].aliases;
                    const wirelessAlarmTypes =
                        createWirelessAlarmTypesDataSource(
                            alarmTypes.filter(
                                (item) => item.isAvailableForWireless,
                            ),
                        ).map((item) => {
                            return {
                                value: item.value,
                                label:
                                    alarmTypesAliases[item.label] || item.label,
                            };
                        });

                    runInAction(() => {
                        this.state.alarmIds = alarmIds;
                        this.state.alarmTypes = wirelessAlarmTypes;
                        this.state.transmitterData = scanResult.transmitterData;
                        this.state.scanning = false;
                        this.state.isKnown = scanResult.isKnown;
                        this.state.knownFieldIdAlias =
                            scanResult.knownFieldIdAlias;
                        this.state.knownFieldIdValue =
                            scanResult.knownFieldIdValue;
                    });
                }
            }
        }, 1000);
    });

    stopScanning = action(() => {
        this.state.scanning = false;
        clearInterval(this.interval);
    });

    cancel = action(() => {
        this.state.isKnown = false;
        this.state.knownFieldIdAlias = '';
        this.state.knownFieldIdValue = '';
        this.state.reprogrammingInProgress = false;
    });

    confirmReprogramming = action(() => {
        this.state.reprogrammingInProgress = true;
        this.state.isKnown = false;
    });
}
