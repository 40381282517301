import baseRoutes from 'configuration/routing/baseRoutes';
import orderBy from 'lodash-es/orderBy';
import environmentUtils from 'common/utils/environmentUtils';

const moduleIds = {
    home: 'home',
    activeAlarms: 'activeAlarms',
    alarmLog: 'alarmLog',
    reports: 'reports',
    wireless: 'wireless',
    actionTags: 'actionTags',
    users: 'users',
    logout: 'logout',
    smartphones: 'smartphones',
    technicalAlarms: 'technicalAlarms',
};

const items = [
    {
        uid: 1,
        id: moduleIds.home,
        path: baseRoutes.main,
        displayName: 'Home.Title',
        icon: 'home',
        availableForHome: false,
        hidden: true,
    },
    {
        uid: 2,
        id: moduleIds.home,
        path: `/${baseRoutes.main}/${baseRoutes.home}`,
        displayName: 'Navigation.Home',
        icon: 'home',
        availableForHome: false,
    },
    {
        uid: 3,
        id: moduleIds.activeAlarms,
        path: `/${baseRoutes.main}/${baseRoutes.activeAlarms}`,
        displayName: 'Navigation.ActiveAlarms',
        configurableName: null,
        icon: 'notifications_active',
        availableForHome: true,
    },
    {
        uid: 13,
        id: moduleIds.technicalAlarms,
        path: `/${baseRoutes.main}/${baseRoutes.technicalAlarms}`,
        displayName: 'Navigation.TechnicalAlarms',
        configurableName: null,
        icon: 'notification_important',
        availableForHome: true,
    },
    {
        uid: 4,
        id: moduleIds.alarmLog,
        path: `/${baseRoutes.main}/${baseRoutes.alarmLog}`,
        displayName: 'Navigation.AlarmLog',
        configurableName: null,
        icon: 'view_list',
        availableForHome: true,
    },
    {
        uid: 5,
        id: moduleIds.reports,
        path: `/${baseRoutes.main}/${baseRoutes.reports}`,
        displayName: 'Navigation.Reports',
        configurableName: null,
        icon: 'assignment',
        availableForHome: true,
    },
    {
        uid: 6,
        id: moduleIds.wireless,
        path: `/${baseRoutes.main}/${baseRoutes.wireless}`,
        displayName: 'Navigation.Wireless',
        configurableName: null,
        icon: 'wifi',
        availableForHome: true,
    },
    {
        uid: 7,
        id: moduleIds.actionTags,
        path: `/${baseRoutes.main}/${baseRoutes.actionTags}`,
        displayName: 'Navigation.ActionTags',
        configurableName: null,
        icon: 'bookmark',
        availableForHome: true,
    },
    {
        uid: 8,
        id: moduleIds.users,
        path: `/${baseRoutes.main}/${baseRoutes.users}`,
        displayName: 'Navigation.Users',
        configurableName: null,
        availableForHome: true,
        icon: 'group',
    },
    {
        uid: 9,
        id: moduleIds.reports,
        path: baseRoutes.templates,
        displayName: 'Navigation.Reports',
        hidden: true,
        availableForHome: false,
    },
    {
        uid: 12,
        id: moduleIds.smartphones,
        path: `/${baseRoutes.main}/${baseRoutes.devices}`,
        displayName: 'Navigation.Smartphones',
        availableForHome: true,
        icon: 'phone_android',
    },
    {
        uid: 10,
        id: moduleIds.users,
        path: `/${baseRoutes.main}/${baseRoutes.changeOwnPassword}`,
        displayName: 'Navigation.ChangeOwnPassword',
        availableForHome: false,
        icon: 'lock',
    },
    {
        uid: 11,
        id: moduleIds.logout,
        path: baseRoutes.index,
        displayName: 'Navigation.Logout',
        configurableName: null,
        icon: 'exit_to_app',
        availableForHome: false,
        hidden: false,
        withSeparator: true,
    },
];

const getItems = () => {
    const modules = environmentUtils.modules;
    const userModules = environmentUtils.userModules || [];
    let maxId = Math.max(...items.map((i) => i.uid));
    const allItems = [
        ...items,
        ...userModules.map((ui) =>
            Object.assign({}, { ...ui }, { uid: ++maxId, type: 'user' }),
        ),
    ];
    if (!modules) return allItems;
    for (const menuItem of allItems) {
        if (menuItem.id === moduleIds.logout) continue;
        if (menuItem.type === 'user') {
            continue;
        }
        const item = modules[menuItem.id];
        menuItem.configurableName = item?.displayName;
        menuItem.hidden = menuItem.hidden || !item;
        menuItem.sortCode = item?.sortCode;
    }

    return orderBy(allItems, ['sortCode'], ['asc']);
};

export default getItems;
