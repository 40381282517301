import { getUserLocale } from 'core/locale/localesStorage';
import { tryParseJson } from 'common/utils/generalUtils';
import { SESSION_TOKEN, getAuthToken } from './authTokenService';

const API_PREFIX = '/1/';

export async function post(url, params) {
    return await call(url, 'POST', params);
}

export const get = (url, params, plainText = false) => {
    return call(url, 'GET', params, plainText);
};

export const put = (url, params) => {
    return call(url, 'PUT', params);
};

export const patch = (url, params) => {
    return call(url, 'PATCH', params);
};

export const del = (url, params) => {
    return call(url, 'DELETE', params);
};

async function call(url, method, params, plainText = false) {
    const token = getAuthToken();
    const language = getUserLocale();
    const fullEndpoint = `${API_PREFIX}${url}`;

    try {
        const response = await fetch(fullEndpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept-Language': language,
                [SESSION_TOKEN]: token,
            },
            body: params ? JSON.stringify(params) : null,
        });

        if (!response.ok) {
            const errorResult = await response.text();
            const parseResult = tryParseJson(errorResult);
            return {
                success: false,
                error: parseResult.success ? parseResult.value : errorResult,
            };
        }

        if (plainText) {
            return await response.text();
        }
        let responseJson;
        try {
            responseJson = await response.json();
        } catch (ex) {
            // JSON parse exception
            responseJson = null;
        }
        return {
            success: true,
            data: responseJson,
        };
    } catch (error) {
        return {
            success: false,
            error: `General error - ${error}`,
        };
    }
}
