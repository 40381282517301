export default class WebsocketMessageBuilder {
    constructor() {
        this.message = [];
    }

    ofType(type) {
        this.message.push(type);
        return this;
    }

    withId(id) {
        this.message.push(id);
        return this;
    }

    withName(name) {
        this.message.push(name);
        return this;
    }

    withData(data) {
        this.message.push(data);
        return this;
    }

    build() {
        return JSON.stringify(this.message);
    }
}
