import React from 'react';
import { observer } from 'mobx-react-lite';
import getLocale from 'core/locale/locale';
import { getUserLocale } from 'core/locale/localesStorage';
import { useStore } from 'core/state/StoreProvider';

const withLocale = (WrappedComponent) => {
    return observer((props) => {
        const rootStore = useStore();
        const localesStore = rootStore.localesStore;
        const selectedLang = getUserLocale();
        const locale = getLocale(selectedLang);
        return (
            <WrappedComponent
                {...props}
                locale={locale}
                lang={localesStore.language}
            />
        );
    });
};

export default withLocale;
