import { makeAutoObservable, runInAction } from 'mobx';
import { getDevices } from './devicesService';

export default class DevicesStore {
    supportedClassName = 'BxoPager2';

    isReady = false;

    bxoPager2Devices = [];
    defaultDevice = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async initialize() {
        this.isReady = false;
        this.defaultDevice = null;

        const result = await getDevices();
        runInAction(() => {
            if (result.success) {
                this.bxoPager2Devices = result.data.filter(
                    (item) => item.className === this.supportedClassName,
                );
                if (this.bxoPager2Devices.length === 1) {
                    this.defaultDevice = this.bxoPager2Devices[0].id;
                }
            }
            this.isReady = true;
        });
    }
}
