import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Input from 'common/components/form/controls/Input';
import withLocale from 'common/hoc/withLocale';

const schema = yup.object().shape({
    oldPassword: yup.string().required(true),
    newPassword: yup.string().required(true),
    newPasswordConfirmation: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], true)
        .required(true),
});

const ChangeOwnPasswordForm = ({ onCancel, onSave, locale }) => {
    return (
        <Dialog open={true}>
            <DialogTitle id="alert-dialog-title">
                {locale.Security.ChangePassword}
            </DialogTitle>
            <Formik
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                }}
                onSubmit={onSave}
                validationSchema={schema}
                enableReinitialize
            >
                {(props) => {
                    const touched =
                        props.touched.newPassword &&
                        props.touched.newPasswordConfirmation;
                    const passwordsDoNotMatch =
                        props.errors.newPasswordConfirmation;
                    const showNoMatchError = touched && passwordsDoNotMatch;
                    return (
                        <Form>
                            <DialogContent>
                                <Field
                                    type="password"
                                    name="oldPassword"
                                    label={locale.Security.OldPassword}
                                    component={Input}
                                />
                                <Field
                                    type="password"
                                    name="newPassword"
                                    label={locale.Security.NewPassword}
                                    component={Input}
                                />
                                <Field
                                    type="password"
                                    name="newPasswordConfirmation"
                                    label={locale.Security.RetypeNewPassword}
                                    component={Input}
                                />
                                {showNoMatchError && (
                                    <FormHelperText error>
                                        {locale.Security.PasswordsMustMatch}
                                    </FormHelperText>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onCancel}>
                                    {locale.Common.Cancel}
                                </Button>
                                <Button type="submit" color="primary" autoFocus>
                                    {locale.Common.Save}
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

ChangeOwnPasswordForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    locale: PropTypes.object.isRequired,
};

export default withLocale(ChangeOwnPasswordForm);
