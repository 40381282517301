import orderBy from 'lodash-es/orderBy';
import startsWith from 'lodash-es/startsWith';
import { action, observable, runInAction, when } from 'mobx';
import getBuiltInTemplates from './../templates/builtInTemplatesProvider';
import { getTokenForReport } from './reportTokenService';
import messages from './requestedMessages';
import {
    createRequestedDataSource,
    deleteReport,
    getReportsActivationData,
    isAllowedReportsMessage,
    isSuccessfulReportActivationResponse,
    mergeReportsBufferedDataWithActivations,
    processReportNotification,
} from './requestedService';

export default class RequestedReportsStore {
    websocketSubscriberName = 'requested-reports';

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        isSubscribed: false,
        isActivated: false,
        rawData: [],
        data: [],
        deleteConfirmationVisible: false,
        deletedReport: '',
    });

    get list() {
        if (!this.state.data) {
            return [];
        }
        const searchText =
            this.rootStore.reportsStore.requestedFiltersStore.state.text;
        const options =
            this.rootStore.reportsStore.requestedFiltersStore.state.options;
        const filteredData = this.state.data.filter(
            (item) =>
                startsWith(
                    item.title.toLowerCase(),
                    searchText.toLowerCase(),
                ) && options.indexOf(item.status) > -1,
        );
        return orderBy(filteredData, ['generatedOn'], ['desc']);
    }

    subscribe = () => {
        when(
            () => this.rootStore.websocketStore.state.connectionOpened,
            this.createMessageHandler,
        );
    };

    unsubscribe = () => {
        this.rootStore.websocketStore.send(messages.unsubscribe);
        this.rootStore.websocketStore.unsubscribe(this.websocketSubscriberName);
        runInAction(() => {
            this.isSubscribed = false;
            this.data = [];
            this.rawData = [];
        });
    };

    createMessageHandler = () => {
        this.rootStore.websocketStore.subscribe({
            name: this.websocketSubscriberName,
            callback: (message) => {
                if (!isAllowedReportsMessage(message)) {
                    return;
                }
                const currentDataState = this.state.rawData;
                const builtInTemplates = getBuiltInTemplates();
                if (isSuccessfulReportActivationResponse(message)) {
                    const activationsData = getReportsActivationData(message);
                    const mergeResult = mergeReportsBufferedDataWithActivations(
                        currentDataState,
                        activationsData,
                    );
                    const dataSource = createRequestedDataSource(
                        mergeResult,
                        builtInTemplates.data,
                    );
                    runInAction(() => {
                        this.state.isActivated = true;
                        this.state.rawData = mergeResult;
                        this.state.data = dataSource;
                    });
                } else {
                    const isActivated = this.state.isActivated;
                    const rawData = processReportNotification(
                        message,
                        currentDataState,
                        isActivated,
                    );
                    if (rawData) {
                        const dataSource = createRequestedDataSource(
                            rawData,
                            builtInTemplates.data,
                        );
                        runInAction(() => {
                            this.state.rawData = rawData;
                            this.state.data = dataSource;
                        });
                    }
                }
            },
        });
        this.rootStore.websocketStore.send(messages.subscribe);
        this.rootStore.websocketStore.send(messages.getActivations);
    };

    getReportToken = async (id) => {
        const tokenResult = await getTokenForReport(id);
        return tokenResult;
    };

    showRemoveReport = action((report) => {
        this.state.deleteConfirmationVisible = true;
        this.state.deletedReport = report;
    });

    confirmRemove = async () => {
        const { id, title } = this.state.deletedReport;
        const deleteResult = await deleteReport(id);
        if (deleteResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'Reports.ReportRemoveSuccess',
                title,
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'Reports.ReportRemoveFailed',
                title,
                'danger',
            );
        }
        runInAction(() => {
            this.state.deleteConfirmationVisible = false;
            this.state.deletedReport = null;
        });
    };

    rejectRemove = action(() => {
        this.state.deleteConfirmationVisible = false;
    });
}
