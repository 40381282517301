import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Input from 'common/components/form/controls/Input';

const Properties = ({ locale }) => {
    const locales = locale.Reports;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.Properties}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="title"
                    type="text"
                    component={Input}
                    label={locales.Title}
                />
            </Grid>
        </Grid>
    );
};

Properties.propTypes = {
    locale: PropTypes.object.isRequired,
};

const exported = {
    component: Properties,
    key: 'properties',
};

export default exported;
