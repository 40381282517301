import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import MultiSelector from 'common/components/multi-selector/MultiSelector';

const AlarmType = (props) => {
    const fieldName = 't.ftvs';
    const [alarmTypes, setAlarmTypes] = useState([]);

    useEffect(() => {
        const internalOptions = props.options.alarmTypes.map((item) => {
            return {
                header: item.label,
                id: item.value,
            };
        });
        setAlarmTypes(internalOptions);
    }, [props.options.alarmTypes]);

    const onSearch = (value) => {
        const filteredOptions = props.options?.alarmTypes.filter((item) =>
            item.value.toLowerCase().includes(value.toLowerCase()),
        );
        setAlarmTypes(filteredOptions);
    };

    const handleSelection = (fieldName, selection) => {
        const formProps = props.formProps;
        formProps.setFieldValue(
            fieldName,
            selection.map((item) => item.id),
        );
    };

    const getValue = () => {
        const formProps = props.formProps;
        if (!alarmTypes) {
            return [];
        }
        const rawValues = get(formProps.values, fieldName);
        const value = rawValues.map((item) => {
            return alarmTypes.find((option) => option.id === item);
        });
        return value || [];
    };

    const { locale } = props;
    const locales = locale.Reports;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.AlarmType}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {locales.IncludeOnlyAlarmsOfType}
                </Typography>
                <MultiSelector
                    onSearch={onSearch}
                    name={fieldName}
                    value={getValue()}
                    onChange={handleSelection}
                    options={alarmTypes}
                    withDefaultSearch={true}
                />
            </Grid>
        </Grid>
    );
};

AlarmType.propTypes = {
    locale: PropTypes.object.isRequired,
    options: PropTypes.object,
    formProps: PropTypes.object,
};

const exported = {
    component: AlarmType,
    key: 'alarmType',
};

export default exported;
