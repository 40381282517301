import React from 'react';
import throttle from 'lodash-es/throttle';

export default class UserActivity extends React.Component {
    static isActive = false;

    componentDidMount() {
        document.addEventListener('click', this.setActive);
        document.addEventListener('touchstart', this.setActive);
        document.addEventListener('touchend', this.setActive);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.setActive);
        document.removeEventListener('touchstart', this.setActive);
        document.removeEventListener('touchend', this.setActive);
    }

    render() {
        return null;
    }

    setActive = throttle(() => {
        UserActivity.isActive = true;
    }, 500);
}
