import * as api from 'core/communcation/api';

export const updateWirelessDevice = (formValues) => {
    const { id, ...dto } = formValues;
    const updateDeviceEndPoint = `wirelessdevices/devices/${encodeURIComponent(
        id,
    )}`;
    return api.put(updateDeviceEndPoint, dto);
};

export const addWirelessDevice = (formValues) => {
    const { transmitterId, ...dto } = formValues;
    const addDeviceEndPoint = `wirelessdevices/devices/${encodeURIComponent(
        transmitterId,
    )}`;
    return api.put(addDeviceEndPoint, dto);
};

export const scanForWirelessDevices = (lastScanTime) => {
    const wirelessScanEndPoint = 'wirelessdevices/lastreceived';
    return api.get(wirelessScanEndPoint).then((response) => {
        const scanResponse = {
            success: response.success,
            scanTime: null,
            deviceFound: false,
            lastScanTime: null,
            isKnown: false,
            knownFieldIdAlias: '',
            knownFieldIdValue: '',
            transmitterData: {
                transmitterId: null,
                deviceType: null,
                alarmType: null,
                alarmId: null,
            },
        };
        if (response.success && response.data) {
            const {
                transmitterId,
                detectedDeviceType,
                detectedAlarmType,
                detectedSupervisionInterval,
                isKnown,
                knownFieldIdAlias,
                knownFieldIdValue,
                receivedTime,
            } = response.data;
            scanResponse.success = true;
            scanResponse.transmitterData = {
                transmitterId: transmitterId,
                deviceType: detectedDeviceType,
                defaultFieldTypeValue: detectedAlarmType,
                supervisionInterval: detectedSupervisionInterval,
                alarmId: ' ',
            };
            scanResponse.isKnown = isKnown;
            scanResponse.knownFieldIdAlias = knownFieldIdAlias;
            scanResponse.knownFieldIdValue = knownFieldIdValue;
            scanResponse.deviceFound = receivedTime !== lastScanTime;
            scanResponse.lastScanTime = receivedTime;
        }
        return scanResponse;
    });
};

export const getLastScanTime = async () => {
    try {
        const response = await api.get('wirelessdevices/lastreceived');
        if (response.success && response.data) {
            return response.data.receivedTime;
        }
        return null;
    } catch {
        console.warn('Failed to get response from server.');
        return null;
    }
};
