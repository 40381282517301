import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const styles = () => ({
    root: {
        position: 'relative',
        top: '10vh',
    },
});

const ErrorZone = ({ classes, children }) => {
    return <div className={classes.root}>{children}</div>;
};

ErrorZone.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withStyles(styles)(ErrorZone);
