import React, { Suspense } from 'react';
import { RoutingContextProvider } from 'configuration/routing/routingParams';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentLogin = React.lazy(() => import('./Login'));

const LoadableLogin = (props) => (
    <RoutingContextProvider routingParams={props}>
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentLogin />
        </Suspense>
    </RoutingContextProvider>
);

export default LoadableLogin;
