import WebsocketMessageBuilder from 'common/websocket/websocketMessageBuilder';

const subscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType('request')
        .withId(777)
        .withName('subscribe')
        .withData({
            stream: 'alarms',
            topic: 'alarms',
        })
        .build();
};

const unsubscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType('request')
        .withId(778)
        .withName('unsubscribe')
        .withData({
            stream: 'alarms',
        })
        .build();
};

export default {
    subscribe: subscribe(),
    unsubscribe: unsubscribe(),
};
