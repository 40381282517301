export const parseNotification = (notification) => JSON.parse(notification);

export const mergeBufferedDataWithActivations = (
    currentData,
    activationsData,
) => {
    const { activations, logIndex } = activationsData;
    const validBufferedData = currentData.filter(
        (item) => item.logIndex > logIndex,
    );
    return [...validBufferedData, ...activations];
};
