import * as api from 'core/communcation/api';
import {
    frameType,
    subscription,
} from '../../common/helpers/webSockets/webSocketConsts';

export const fetchAlarmLogs = async () => {
    const alarmLogEndPoint = 'loggedalarms/recent';
    return api.get(alarmLogEndPoint);
};

export const getMessageData = (message) => {
    const isNotification =
        message.length > 0 && message[0] === frameType.NOTIFICATION;
    if (!isNotification) {
        return [];
    }
    if (message.length > 1 && message[1] !== subscription) {
        return [];
    }
    if (message.length > 2 && message[2].stream !== 'alarms') {
        return [];
    }
    const alarmValue = message[2].data.alarm;
    return [alarmValue];
};
