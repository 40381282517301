import * as api from 'core/communcation/api';

export const addActionTag = (tag) => {
    const actionTagEndPoint = `actiontags/tags/${encodeURIComponent(tag.id)}`;
    return api.put(actionTagEndPoint, tag);
};

export const getTagById = (tagId) => {
    const actionTagEndPoint = `actiontags/tags/${encodeURIComponent(tagId)}`;
    return api.get(actionTagEndPoint);
};

export const updateActionTag = (tag) => {
    const actionTagEndPoint = `actiontags/tags/${encodeURIComponent(tag.id)}`;
    return api.put(actionTagEndPoint, tag);
};

export const scanForTags = (lastScanTime) => {
    const actionTagsEndPoint = 'actiontags/lastscanned';
    return api.get(actionTagsEndPoint).then((response) => {
        const scanResponse = {
            success: response.success,
            scanTime: null,
            tagFound: false,
            tagId: null,
            lastScanTime: null,
            isKnown: false,
        };
        if (response.success && response.data) {
            scanResponse.success = true;
            const { tagId, scanTime, isKnown } = response.data;
            scanResponse.tagId = tagId;
            scanResponse.tagFound = scanTime !== lastScanTime;
            scanResponse.lastScanTime = scanTime;
            scanResponse.isKnown = isKnown;
        }
        return scanResponse;
    });
};

export const getLastScanTime = async () => {
    const actionTagsEndPoint = 'actiontags/lastscanned';
    try {
        const response = await api.get(actionTagsEndPoint);
        if (response.success && response.data) {
            return response.data.scanTime;
        }
        return null;
    } catch {
        console.warn('Failed to get response from server.');
        return null;
    }
};
