import { makeAutoObservable, runInAction, when } from 'mobx';
import nextActions from 'scenes/main/smartphones/helpers/nextActions';
import WebsocketMessageBuilder from 'common/websocket/websocketMessageBuilder';
import {
    addPhone,
    deletePhone,
    getProfiles,
    getRemainingLicenses,
    getStatus,
    replacePhone,
    updateSmartphone,
    verifyCode,
} from '../smartphonesService';

export default class SmartphonesStore {
    supportedClassName = 'BxoPager2';
    nextAction = null;

    isReady = false;
    operationInProgress = false;

    verificationCodeFormVisible = false;
    addFormVisible = false;
    editFormVisible = false;
    replaceFormVisible = false;

    deleteConfirmationVisible = false;
    deleteSmartphoneId = null;

    activeVerificationCode = null;

    availableLicenses = null;
    freeLicensesNextStepQuestionVisible = false;
    noFreeLicensesNextStepConfirmationVisible = false;

    smartphones = [];
    profiles = [];
    pagersData = [];

    editedSmartphoneData = null;

    poolingInterval = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async initialize(deviceId) {
        this.deviceId = deviceId;
        runInAction(() => {
            this.isReady = false;
            this.operationInProgress = false;
            this.verificationCodeFormVisible = false;
            this.addFormVisible = false;
            this.editFormVisible = false;
            this.deleteConfirmationVisible = false;
            this.deleteSmartphoneId = null;
            this.smartphones = [];
            this.profiles = [];
            this.activeVerificationCode = null;
            this.freeLicensesNextStepQuestionVisible = false;
            this.noFreeLicensesNextStepConfirmationVisible = false;
            this.replaceFormVisible = false;
            this.editedSmartphoneData = null;
        });

        const smartphonesData = await this.getSmartphonesStatus(
            deviceId,
            false,
        );

        runInAction(() => {
            this.smartphones = smartphonesData;
            this.isReady = true;
        });

        this.restartPooling();
    }

    getSmartphonesStatus = async (deviceId, isSilent = true) => {
        const result = await getStatus(deviceId);
        if (!result.success) {
            if (!isSilent) {
                this.rootStore.notificationsStore.showNotification(
                    'Smartphones.ErrorRetrievingSmartphonesList',
                );
            }
            return [];
        }

        const smartphonesData = result.data.Status.PagerStatuses.map((item) => {
            const {
                ClientId,
                PhoneNumber,
                SecondaryPhoneNumber,
                ClientAlias,
                IsOnline,
                User,
                ProfileId,
            } = item;
            return {
                clientId: ClientId,
                phoneNumber: PhoneNumber,
                secondaryPhoneNumber: SecondaryPhoneNumber,
                clientAlias: ClientAlias,
                isOnline: IsOnline,
                user: User,
                profileId: ProfileId,
            };
        });
        return smartphonesData;
    };

    poolForStatus = async () => {
        const smartphonesData = await this.getSmartphonesStatus(this.deviceId);
        if (!smartphonesData) return;

        runInAction(() => {
            this.smartphones = smartphonesData;
        });
    };

    startPooling = () => {
        this.poolingInterval = setInterval(() => {
            this.poolForStatus();
        }, 5000);
    };

    stopPooling = () => {
        clearInterval(this.poolingInterval);
    };

    restartPooling = () => {
        this.stopPooling();
        this.startPooling();
    };

    subscribe = () => {
        when(
            () => this.rootStore.websocketStore.state.connectionOpened,
            this.createMessageHandler,
        );
    };

    createMessageHandler = () => {
        const subscriberName = 'smartphones';
        const messageBuilder = new WebsocketMessageBuilder();
        const pagersMessage = messageBuilder
            .ofType('request')
            .withId(668)
            .withName('getPagers')
            .withData(null)
            .build();

        this.rootStore.websocketStore.subscribe({
            name: subscriberName,
            callback: (message) => {
                const isSuccessResponse =
                    message.length !== 4 ||
                    message[0] !== 'response' ||
                    message[1] === 668 ||
                    message[2] !== 200;
                if (!isSuccessResponse) {
                    this.rootStore.websocketStore.send(pagersMessage);
                    return;
                }
                const pagers = message[3]?.roots;
                this.pagersData = this.preparePagersData(pagers);
                this.rootStore.websocketStore.unsubscribe(subscriberName);
            },
        });

        this.rootStore.websocketStore.send(pagersMessage);
    };

    preparePagersData = (pagers) => {
        const normalRoot = pagers.filter((x) => x.function === 'NormalRoot');
        return normalRoot.length > 0 && normalRoot[0];
    };

    async verifyCode(deviceId, code) {
        runInAction(() => {
            this.operationInProgress = true;
        });
        const verificationCode = code?.toUpperCase();
        const result = await verifyCode(deviceId, verificationCode);

        runInAction(() => {
            this.operationInProgress = false;
        });

        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'Smartphones.VerificationCodeCheckError',
            );
            return;
        }

        if (!result.data.IsKnown) {
            this.rootStore.notificationsStore.showNotification(
                'Smartphones.VerificationCodeInvalid',
            );
            return;
        }

        this.activeVerificationCode = verificationCode;

        switch (this.nextAction) {
            case nextActions.add:
                this.openAddForm(deviceId);
                break;
            case nextActions.replace:
                this.openReplaceForm(deviceId);
                break;
            default:
                console.warn('Unsupported action');
                runInAction(() => {
                    this.verificationCodeFormVisible = false;
                    this.activeVerificationCode = null;
                    this.nextAction = null;
                });
                break;
        }
    }

    async getLicenses(deviceId) {
        const licensesCheckResult = await getRemainingLicenses(deviceId);
        if (licensesCheckResult.success) {
            return licensesCheckResult.data;
        }
        this.rootStore.notificationsStore.showNotification(
            'Smartphones.LicensesCheckError',
        );
        return null;
    }

    openVerificationCodeForm(nextAction) {
        this.stopPooling();
        this.verificationCodeFormVisible = true;
        this.freeLicensesNextStepQuestionVisible = false;
        this.noFreeLicensesNextStepConfirmationVisible = false;
        this.nextAction = nextAction;
    }

    closeVerificationCodeForm() {
        this.verificationCodeFormVisible = false;
        this.activeVerificationCode = null;
        this.startPooling();
    }

    openAddForm() {
        this.stopPooling();
        this.addFormVisible = true;
        this.verificationCodeFormVisible = false;
    }

    closeAddForm() {
        this.addFormVisible = false;
        this.verificationCodeFormVisible = false;
        this.activeVerificationCode = null;
        this.startPooling();
    }

    openReplaceForm() {
        this.stopPooling();
        this.replaceFormVisible = true;
        this.verificationCodeFormVisible = false;
    }

    closeReplaceForm() {
        this.replaceFormVisible = false;
        this.verificationCodeFormVisible = false;
        this.activeVerificationCode = null;
        this.startPooling();
    }

    async replaceSmartphone(deviceId, replacedSmartphoneId) {
        runInAction(() => {
            this.operationInProgress = true;
        });

        const replaceSmartphoneDto = {
            Code: this.activeVerificationCode,
            ReplacedClientId: replacedSmartphoneId,
        };
        await replacePhone(deviceId, replaceSmartphoneDto);

        runInAction(() => {
            this.operationInProgress = false;
            this.closeReplaceForm();
        });
    }

    async triggerAddReplaceForm(deviceId) {
        runInAction(() => {
            this.operationInProgress = true;
        });

        const availableLicenses = await this.getLicenses(deviceId);
        runInAction(() => {
            this.operationInProgress = false;
            this.availableLicenses = availableLicenses;
            const anyFreeLicenses = availableLicenses && availableLicenses > 0;
            this.freeLicensesNextStepQuestionVisible = anyFreeLicenses;
            this.noFreeLicensesNextStepConfirmationVisible = !anyFreeLicenses;
        });
    }

    async openEditForm(deviceId, smartphoneId) {
        this.stopPooling();
        const result = await getProfiles(deviceId);
        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'Smartphones.GetProfilesError',
            );
            return;
        }

        runInAction(() => {
            this.profiles = result.data.Profiles;
            this.editedSmartphoneData = this.smartphones.find(
                (item) => item.clientId === smartphoneId,
            );
            this.editFormVisible = true;
        });
    }

    closeEditForm() {
        this.editFormVisible = false;
        this.startPooling();
    }

    async confirmRemove(deviceId) {
        const result = await deletePhone(deviceId, this.deleteSmartphoneId);
        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'Smartphones.RemoveSmartphoneError',
            );
            this.deleteConfirmationVisible = false;
            return;
        }
        this.initialize(deviceId);
    }

    rejectRemove() {
        this.deleteConfirmationVisible = false;
    }

    openDeleteQuestion(smartphoneId) {
        this.deleteSmartphoneId = smartphoneId;
        this.deleteConfirmationVisible = true;
    }

    cancelAfterLicensesCheckQuestion() {
        this.freeLicensesNextStepQuestionVisible = false;
        this.noFreeLicensesNextStepConfirmationVisible = false;
        this.availableLicenses = null;
    }

    async updateSmartphoneData(deviceId, formData) {
        runInAction(() => {
            this.operationInProgress = true;
        });

        const { clientAlias, ...rest } = formData;
        const inputDto = { ...rest, alias: clientAlias };
        const result = await updateSmartphone(deviceId, inputDto);

        runInAction(() => {
            this.operationInProgress = false;
        });

        if (result.success) {
            this.initialize(deviceId);
            return;
        }
        this.rootStore.notificationsStore.showNotification(
            'Smartphones.UpdateSmartphoneError',
            null,
            'error',
        );
    }

    async addSmartphone(deviceId, formData) {
        runInAction(() => {
            this.operationInProgress = true;
        });

        const { primaryPhoneNumber, alias, pagingConfigurationGroupId } =
            formData;
        const addSmartphoneDto = {
            code: this.activeVerificationCode,
            address: primaryPhoneNumber,
            alias: alias,
            groupId: +pagingConfigurationGroupId,
        };

        const result = await addPhone(deviceId, addSmartphoneDto);

        runInAction(() => {
            this.operationInProgress = false;
        });

        if (result.success) {
            this.initialize(deviceId);
            return;
        }
        this.rootStore.notificationsStore.showNotification(
            'Smartphones.AddSmartphoneError',
            null,
            'error',
        );
    }
}
