import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    root: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
    },
});

const MainZone = ({ classes, children }) => {
    return <div className={classes.root}>{children}</div>;
};

MainZone.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default withStyles(styles)(MainZone);
