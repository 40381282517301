import * as api from 'core/communcation/api';
import { TemplateType } from '../../helpers/enums';
import getIconByReportClassId from '../common/iconsHelper';

export const fetchCustomTemplatesList = () => {
    return api.get('reporting/templates');
};

export const deleteTemplate = (templateId) => {
    return api.del(`reporting/templates/${templateId}`);
};

export const createNewTemplate = (formValues) => {
    const { title, reportClassId, ...criteria } = formValues;

    let reportCriteria = criteria;
    if (criteria.su && criteria.su.ns) {
        const securityUsersValue = criteria.su.ns;
        if (securityUsersValue.length === 1 && securityUsersValue[0] == null) {
            reportCriteria.su.ns = [];
        }
    }

    const reportParams = {
        name: title,
        reportClassId,
        criteriaJson: JSON.stringify(reportCriteria),
    };
    return api.post('reporting/templates', reportParams);
};

export const createCustomTemplatesDataSource = (
    customTemplates,
    builtInTemlates,
) => {
    if (!customTemplates) {
        return [];
    }
    return customTemplates.map((template) => {
        const criteriaJson = JSON.parse(template.criteriaJson, (key, value) => {
            if (key === 'range' || key === 'mode') {
                return value.toString();
            }
            return value;
        });
        const item = {
            id: template.id,
            reportClassId: template.reportClassId,
            name: template.name,
            title: template.name,
            criteriaJson: criteriaJson,
            type: TemplateType.Custom,
            icon: getIconByReportClassId(
                template.reportClassId,
                builtInTemlates.data,
            ),
        };
        return item;
    });
};
