import * as api from 'core/communcation/api';
import { processNotification } from '../../../common/helpers/webSockets/defaultNotificationProcessor';
import {
    mergeBufferedDataWithActivations,
    parseNotification,
} from '../../../common/helpers/webSockets/notificationsHelper';
import { frameType } from '../../../common/helpers/webSockets/webSocketConsts';
import getIconByReportClassId from '../common/iconsHelper';

export const fetchRequestedReportsList = () => {
    return api.get('reporting/reports');
};

export const deleteReport = (reportId) => {
    return api.del(`reporting/reports/${reportId}`);
};

export const requestNewReport = (formValues) => {
    const { title, reportClassId, ...criteria } = formValues;

    let reportCriteria = criteria;
    if (criteria.su && criteria.su.ns) {
        const securityUsersValue = criteria.su.ns;
        if (securityUsersValue.length === 1 && securityUsersValue[0] == null) {
            reportCriteria.su.ns = [];
        }
    }
    const criteriaJson = JSON.stringify(reportCriteria);
    const reportParams = {
        title,
        reportClassId,
        criteriaJson,
    };
    return api.post('reporting/reports', reportParams);
};

export const createRequestedDataSource = (
    requestedReports,
    builtInTemplates,
) => {
    if (!requestedReports) {
        return [];
    }
    return requestedReports.map((item) => {
        const icon = getIconByReportClassId(
            item.reportClassId,
            builtInTemplates,
        );
        return {
            ...item,
            icon,
        };
    });
};

export const processReportNotification = (
    notification,
    currentDataState,
    isActivated,
) => processNotification(notification, currentDataState, isActivated);

export const mergeReportsBufferedDataWithActivations = (
    currentData,
    activationsData,
) => mergeBufferedDataWithActivations(currentData, activationsData);

export const parseReportNotification = (notification) =>
    parseNotification(notification);

export const getReportsActivationData = (notification) => {
    if (!isSuccessfulReportActivationResponse) {
        return [];
    }
    const notificationValue = notification[3];
    return {
        activations: notificationValue.reports.map((item) => {
            return { ...item, logIndex: notificationValue.logIndex };
        }),
        logIndex: notificationValue.logIndex,
    };
};

export const isSuccessfulReportActivationResponse = (notification) => {
    const isGetActivationsResponse =
        notification.length === 4 &&
        notification[0] === frameType.RESPONSE &&
        notification[1] === 890 &&
        notification[2] === 200 &&
        notification[3];
    return isGetActivationsResponse;
};

export const isAllowedReportsMessage = (notification) => {
    const responseAllowed =
        notification.length >= 2 &&
        notification[0] === frameType.RESPONSE &&
        800 < notification[1] < 900;
    const notificationAllowed =
        notification.length === 3 &&
        notification[2].stream &&
        notification[2].stream === 'reports';
    return responseAllowed || notificationAllowed;
};
