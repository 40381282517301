import { makeAutoObservable } from 'mobx';
import { getUserLocale, persistUserLocale } from 'core/locale/localesStorage';

class LocaleStore {
    language = getUserLocale() || 'en';

    constructor() {
        makeAutoObservable(this);
    }

    setLanguage = (language) => {
        this.language = language;
        persistUserLocale(language);
    };
}

export default LocaleStore;
