import React, { Suspense } from 'react';
import { RoutingContextProvider } from 'configuration/routing/routingParams';
import PageSpinner from 'common/components/spinner/PageSpinner';
import AppThemeProvider from 'common/components/theme/AppThemeProvider';
import withAuthenticationCheck from 'common/hoc/withAuthenticationCheck';

const LoadableComponentMain = React.lazy(() => import('./Main'));

const LoadableMain = ({ location, navigate, children }) => {
    return (
        <AppThemeProvider>
            <RoutingContextProvider
                routingParams={{
                    location,
                    navigate,
                }}
            >
                <Suspense fallback={<PageSpinner />}>
                    <LoadableComponentMain>{children}</LoadableComponentMain>
                </Suspense>
            </RoutingContextProvider>
        </AppThemeProvider>
    );
};

export default withAuthenticationCheck(LoadableMain);
