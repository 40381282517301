import React from 'react';
import {
    StyledEngineProvider,
    ThemeProvider,
    createTheme,
} from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'core/state/StoreProvider';

const defaultPrimaryColor = '#2196f3';
const defaultSecondaryColor = '#f50057';

const AppThemeProvider = observer((props) => {
    const rootStore = useStore();
    const themeStore = rootStore.themeStore;

    const { primary, secondary } = themeStore.theme.colors;
    const isDev = process.env.NODE_ENV === 'development';
    const primaryColor = isDev
        ? defaultPrimaryColor
        : primary || defaultPrimaryColor;
    const secondaryColor = isDev
        ? defaultSecondaryColor
        : secondary || defaultSecondaryColor;

    const primaryConfig = {
        main: primaryColor,
    };
    const secondaryConfig = {
        main: secondaryColor,
    };

    const componentsGlobalTheme = {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiPaper: {
            // styleOverrides: { root: { backgroundColor: "unset" } },
        },
    };

    const themes = {
        light: createTheme({
            palette: {
                mode: 'light',
                primary: primaryConfig,
                secondary: secondaryConfig,
            },
            components: componentsGlobalTheme,
        }),
        dark: createTheme({
            palette: {
                mode: 'dark',
                primary: primaryConfig,
                secondary: secondaryConfig,
            },
            components: componentsGlobalTheme,
        }),
    };

    const activeTheme = themeStore.state.lightSelected
        ? themes.light
        : themes.dark;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={activeTheme}>{props.children}</ThemeProvider>
        </StyledEngineProvider>
    );
});

export default AppThemeProvider;
