import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash-es/get';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useStore } from 'core/state/StoreProvider';
import MultiSelector from 'common/components/multi-selector/MultiSelector';
import TargetSearch from './../TargetSearch';

const Target = observer(({ locale, formProps }) => {
    const rootStore = useStore();
    const referenceDataStore = rootStore.referenceDataStore;

    const locales = locale.Reports;

    const handleSearch = (searchText) => {
        referenceDataStore.getTargets(searchText);
    };

    const fieldName = 'tg.t';

    const handleSelectionChange = (name, selection, value) => {
        formProps.setFieldValue(fieldName, selection);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.Target}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {locales.IncludeOnlyActionRelatedToTargets}
                </Typography>
                <MultiSelector
                    name={fieldName}
                    value={get(formProps.values, fieldName)}
                    onChange={handleSelectionChange}
                    options={
                        referenceDataStore.state.targets.data
                            ? referenceDataStore.state.targets.data.map(
                                  (item) => {
                                      return {
                                          header: item.header,
                                          id: item.id,
                                      };
                                  },
                              )
                            : []
                    }
                    customSearch={<TargetSearch onSearch={handleSearch} />}
                />
            </Grid>
        </Grid>
    );
});

Target.propTypes = {
    locale: PropTypes.object.isRequired,
    formProps: PropTypes.object,
};

const exported = {
    component: Target,
    key: 'target',
};

export default exported;
