import { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import getLocale from 'core/locale/locale';
import { useStore } from 'core/state/StoreProvider';
import { getUserLocale } from './localesStorage';

export default function useLocale() {
    const rootStore = useStore();
    const localesStore = rootStore.localesStore;
    const persistedTranslationKey = getUserLocale();
    const [activeTranslationKey, setActiveTranslationKey] = useState(
        persistedTranslationKey,
    );

    useEffect(() => {
        const disposer = autorun(() => {
            setActiveTranslationKey(localesStore.language);
        });
        return () => {
            disposer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return getLocale(activeTranslationKey);
}
