import WirelessFiltersStore from './modules/list/wirelessFiltersStore';
import WirelessListStore from './modules/list/wirelessListStore';
import WirelessAddStore from './modules/save/wirelessAddStore';

export default class WirelessStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.wirelessFiltersStore = new WirelessFiltersStore(rootStore);
        this.wirelessListStore = new WirelessListStore(rootStore);
        this.wirelessAddStore = new WirelessAddStore(rootStore);
    }
}
