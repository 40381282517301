import React from 'react';
import ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import Root from 'common/components/Root';
import './assets/fonts/index.css';
import './assets/images/BXO.jpg';
import './assets/material-icons/index.css';
import { get } from './core/communcation/api';

configure({
    enforceActions: 'observed',
});

const bootstrap = async () => {
    const themeResult = await get('theme');
    if (themeResult.success) {
        document.title = themeResult.data.name || 'LAS WebViewer';
        const rootElement = document.getElementById('root');
        const root = ReactDOM.createRoot(rootElement);
        root.render(<Root theme={themeResult.data} />);
        return;
    }
};

bootstrap();
