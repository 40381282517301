import RequestedFiltersStore from './modules/requested/requestedFiltersStore';
import RequestedReportsStore from './modules/requested/requestedStore';
import ReportTemplatesFiltersStore from './modules/templates/templatesFiltersStore';
import ReportTemplatesStore from './modules/templates/templatesStore';

export default class ReportsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.requestedStore = new RequestedReportsStore(rootStore);
        this.requestedFiltersStore = new RequestedFiltersStore(rootStore);
        this.templatesStore = new ReportTemplatesStore(rootStore);
        this.templatesFiltersStore = new ReportTemplatesFiltersStore(rootStore);
    }
}
