import { action, observable } from 'mobx';
import { updateOptions } from 'common/helpers/filterOptionsHelper';
import { TemplateType } from './../../helpers/enums';

export default class ReportTemplatesFiltersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        options: [TemplateType.BuiltIn, TemplateType.Custom],
        text: '',
    });

    textChanged = action((value) => {
        this.state.text = value;
    });

    optionChanged = action((value) => {
        this.state.options = updateOptions(this.state.options, value);
    });

    get selection() {
        const contains = (status) => this.state.options.indexOf(status) > -1;
        const selection = {
            isReportSelected: contains(TemplateType.BuiltIn),
            isTemplateSelected: contains(TemplateType.Custom),
        };
        return selection;
    }

    get typesCount() {
        const count = {
            reports: 0,
            templates: 0,
        };
        const types =
            this.rootStore.reportsStore.templatesStore.state.fetchedData;
        if (!types) {
            return count;
        }
        // eslint-disable-next-line no-unused-vars
        for (const item of types) {
            if (item.type === TemplateType.BuiltIn) {
                count.reports++;
            }
            if (item.type === TemplateType.Custom) {
                count.templates++;
            }
        }
        return count;
    }
}
