import en from 'assets/translations/en.json';
import es from 'assets/translations/es.json';
import nl from 'assets/translations/nl.json';
import se from 'assets/translations/se.json';
import fr from 'assets/translations/fr.json';

const locales = { en, se, nl, es, fr };

export default (language) => {
    return language ? locales[language] : en;
};
