import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import Checkbox from 'common/components/form/controls/Checkbox';
import Input from 'common/components/form/controls/Input';
import Radio from 'common/components/form/controls/Radio';
import { DateRangeMode, TemplateType } from '../../../helpers/enums';

const DateRange = ({ locale, ...props }) => {
    const formValues = props.formProps.values;
    const isLastNDaysEnabled =
        get(formValues, 'd.range') === DateRangeMode.LastNDays;
    const isAbsoluteAvailable = props.type === TemplateType.BuiltIn;
    const isAbsoluteEnabled =
        get(formValues, 'd.range') === DateRangeMode.Absolute;
    const isTimeOfDayLimited = get(formValues, 'd.toduse');
    const locales = locale.Reports;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.DateRange}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Field
                    value={DateRangeMode.LastMonth}
                    component={Radio}
                    name="d.range"
                    label={locales.PreviousMonth}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    value={DateRangeMode.LastWeek}
                    component={Radio}
                    name="d.range"
                    label={locales.PreviousWeek}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    value={DateRangeMode.Yesterday}
                    component={Radio}
                    name="d.range"
                    label={locales.Yesterday}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    value={DateRangeMode.LastNDays}
                    component={Radio}
                    name="d.range"
                    label={locales.LastDays}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="d.days"
                    type="number"
                    component={Input}
                    disabled={!isLastNDaysEnabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    value={DateRangeMode.Absolute}
                    component={Radio}
                    name="d.range"
                    label={locales.From}
                    disabled={!isAbsoluteAvailable}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="d.min"
                    type="datetime-local"
                    component={Input}
                    label={locales.From.toLowerCase()}
                    disabled={!isAbsoluteEnabled}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="d.max"
                    type="datetime-local"
                    component={Input}
                    label={locales.To.toLowerCase()}
                    disabled={!isAbsoluteEnabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="d.toduse"
                    component={Checkbox}
                    label={locales.IncludeOnlyAlarmsThatOccuredBetween}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="d.todfrom"
                    type="time"
                    component={Input}
                    label={locales.From.toLowerCase()}
                    disabled={!isTimeOfDayLimited}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="d.todto"
                    type="time"
                    component={Input}
                    label={locales.To.toLowerCase()}
                    disabled={!isTimeOfDayLimited}
                />
            </Grid>
        </Grid>
    );
};

DateRange.propTypes = {
    formProps: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    type: PropTypes.string,
};

const exported = {
    component: DateRange,
    key: 'dateRange',
};

export default exported;
