import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentActiveAlarms = React.lazy(() =>
    import('./ActiveAlarms'),
);

export default () => (
    <>
        <PageTitle pluginId="activeAlarms" pluginNameTag="ActiveAlarms" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentActiveAlarms />
        </Suspense>
    </>
);
