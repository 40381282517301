import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentAlarmLog = React.lazy(() => import('./AlarmLog'));

export default () => (
    <>
        <PageTitle pluginId="alarmLog" pluginNameTag="AlarmLog" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentAlarmLog />
        </Suspense>
    </>
);
