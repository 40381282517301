export const SESSION_TOKEN = 'X-LAS-SessionToken';
const SESSION_LOGGED_IN = 'X-LAS-LoggedIn';

export const getAuthToken = () => {
    return JSON.parse(sessionStorage.getItem(SESSION_TOKEN));
};

export const setAuthToken = (token) => {
    sessionStorage.setItem(SESSION_TOKEN, JSON.stringify(token));
};

export const removeAuthToken = () => {
    sessionStorage.removeItem(SESSION_TOKEN);
};

export const setUserLoggedIn = (state) => {
    if (state) {
        sessionStorage.setItem(SESSION_LOGGED_IN, state ? '1' : '0');
    } else {
        sessionStorage.removeItem(SESSION_LOGGED_IN);
    }
};

export const getUserLoggedIn = () => {
    const loggedIn = sessionStorage.getItem(SESSION_LOGGED_IN);
    return loggedIn && loggedIn === '1';
};
