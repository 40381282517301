import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Input from 'common/components/form/controls/Input';
import AlarmIdSelector from '../../common/AlarmIdSelector';

const IdRange = ({
    locale,
    options,
    getFromCriteriaBag,
    addToCriteriaBag,
    formProps,
    isTriggeredFromTemplate,
}) => {
    const fieldName = 'fid.f';
    const currentValue = formProps.values.fid.f;
    const locales = locale.Reports;

    const handleChange = (inputValue, selectionValue) => {
        const separator = selectionValue.length > 0 && inputValue ? ',' : '';
        const combinedValue =
            inputValue +
            separator +
            selectionValue.map((x) => x.value).join(',');

        formProps.setFieldValue(fieldName, combinedValue);
        addToCriteriaBag(fieldName, {
            input: inputValue,
            selection: selectionValue,
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.IdRange}</Typography>
            </Grid>
            <Grid item xs={12}>
                {isTriggeredFromTemplate && (
                    <Field
                        name={fieldName}
                        type="text"
                        component={Input}
                        label={locales.Filter}
                    />
                )}
                {!isTriggeredFromTemplate && (
                    <AlarmIdSelector
                        name={fieldName}
                        label={locales.Filter}
                        alarmIds={options.alarmIds}
                        onChange={handleChange}
                        rawValue={currentValue}
                        valueByType={getFromCriteriaBag(fieldName)}
                    />
                )}
            </Grid>
        </Grid>
    );
};

IdRange.propTypes = {
    locale: PropTypes.object.isRequired,
};

const exported = {
    component: IdRange,
    key: 'idRange',
};

export default exported;
