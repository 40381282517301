import baseRoutes from 'configuration/routing/baseRoutes';

class EnvironmentUtils {
    setClientConfiguration = (configuration) => {
        if (!configuration) {
            return;
        }
        this.clientConfiguration = configuration;
        this._availableModules = configuration?.modules?.filter(
            (x) => x.visible,
        );
        this._createNormalizedModules();
        this.userModules = configuration?.userModules;
    };

    getClientConfiguration = () => this.clientConfiguration;

    setFields = (fields) => {
        this.fields = fields;
    };

    getFields = () => this.fields;

    setAlarmTypes = (alarmTypes) => {
        this.alarmTypes = alarmTypes;
    };

    getAlarmTypes = () => this.alarmTypes;

    getAlarmIdsAliases = () => {
        const alarmIds = this.fields.find((x) => x.id === 'AlarmId');
        if (!alarmIds || !alarmIds.aliases) return {};
        return alarmIds.aliases;
    };

    getAlarmTypesAliases = () => {
        const alarmTypes = this.fields.find((x) => x.id === 'AlarmType');
        if (!alarmTypes || !alarmTypes.aliases) return {};
        return alarmTypes.aliases;
    };

    _createNormalizedModules = () => {
        const modules = {};
        for (const module of this._availableModules) {
            const { visible, ...rest } = module;
            modules[module.name] = { ...rest };
        }
        this.modules = modules;
    };

    getMainRoute = () => {
        const customMainRoute = this.clientConfiguration.customMainRoute;
        return customMainRoute ? customMainRoute : `/${baseRoutes.main}`;
    };
}

export default new EnvironmentUtils();
