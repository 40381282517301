import React from 'react';
import TextField from '@mui/material/TextField';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';

const Input = ({ field, form: { touched, errors }, onBlur, ...props }) => {
    const current = field.name;
    // eslint-disable-next-line eqeqeq
    const hasError = get(errors, current) != undefined && get(touched, current);
    return (
        <TextField
            {...props}
            {...field}
            value={field.value || ''}
            error={hasError}
            onBlur={onBlur}
            margin="normal"
            fullWidth
            autoComplete="off"
        />
    );
};

Input.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    onBlur: PropTypes.func,
};

export default Input;
