import React from 'react';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ErrorZone from 'common/components/ui/zones/ErrorZone';

const ApplicationError = ({ info }) => {
    return (
        <ErrorZone>
            <SnackbarContent
                message="Application error! Please contact administrator."
                action={
                    <Typography
                        variant="caption"
                        color="secondary"
                        gutterBottom
                    >
                        {info}
                    </Typography>
                }
            />
        </ErrorZone>
    );
};

ApplicationError.propTypes = {
    info: PropTypes.string,
};

export default ApplicationError;
