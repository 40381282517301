export function tryParseJson(input) {
    try {
        return {
            success: true,
            value: JSON.parse(input),
        };
    } catch (ex) {
        return {
            success: false,
        };
    }
}
