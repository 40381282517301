import { useEffect } from 'react';
import useLocale from 'core/locale/useLocale';
import { useStore } from 'core/state/StoreProvider';

const PageTitle = ({ pluginId, pluginNameTag }) => {
    const rootStore = useStore();
    const navigationStore = rootStore.navigationStore;
    const locale = useLocale();

    useEffect(() => {
        const pluginName = locale.Navigation[pluginNameTag];
        navigationStore.setCurrentPluginName(pluginId, pluginName);
    }, [locale.Navigation, navigationStore, pluginId, pluginNameTag]);

    return null;
};

export default PageTitle;
