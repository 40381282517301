import baseRoutes from 'configuration/routing/baseRoutes';
import { observable, runInAction } from 'mobx';
import { redirect } from 'common/helpers/routerHelper';
import environmentUtils from 'common/utils/environmentUtils';
import { getTagById, updateActionTag } from './actionTagsSaveService';

export default class ActionTagsUpdateStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        tagData: null,
        fields: null,
        isReady: false,
        hasError: false,
    });

    getTag = async (id) => {
        const tagData = await getTagById(id);

        if (tagData !== undefined && tagData.success) {
            runInAction(() => {
                this.state.tagData = tagData.data;
                this.state.fields = environmentUtils.getFields();
                this.state.isReady = true;
            });
            return;
        }
        this.rootStore.notificationsStore.showNotification(
            'Common.ErrorDuringRequestExecution',
        );
        runInAction(() => {
            this.state.hasError = true;
            this.state.isReady = true;
        });
    };

    updateTag = async (formValues) => {
        const updateResult = await updateActionTag(formValues);
        const tagId = formValues.id;
        if (updateResult.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagUpdateSuccess',
                tagId,
            );
            redirect(`/${baseRoutes.main}/${baseRoutes.actionTags}`);
        } else {
            this.rootStore.notificationsStore.showNotification(
                'ActionTags.TagUpdateFail',
            );
        }
    };
}
