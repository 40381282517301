import { get } from 'core/communcation/api';
import { readJson } from 'core/communcation/externalResourcesReader';

export const getConnectionInfoData = () => {
    return get('hello/license');
};

export const getVersionInfo = () => {
    try {
        const versionJson = readJson(`/BuildInfo.json?t=${Date.now()}`);
        return versionJson;
    } catch (ex) {
        return null;
    }
};
