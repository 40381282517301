import WebsocketMessageBuilder from 'common/websocket/websocketMessageBuilder';

const subscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType('request')
        .withId(666)
        .withName('subscribe')
        .withData({
            stream: 'alarmsStream',
            topic: 'activations',
        })
        .build();
};

const unsubscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType('request')
        .withId(667)
        .withName('unsubscribe')
        .withData({
            stream: 'alarmsStream',
        })
        .build();
};

const getActivations = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType('request')
        .withId(668)
        .withName('getActivations')
        .withData(null)
        .build();
};

export default {
    subscribe: subscribe(),
    unsubscribe: unsubscribe(),
    getActivations: getActivations(),
};
