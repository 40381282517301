import { observable, runInAction } from 'mobx';
import { readTargets } from 'core/environment/environmentService';

export default class ReferenceDataStore {
    state = observable({
        targets: {
            data: [],
            error: false,
        },
    });

    getTargets = async (searchText) => {
        const targets = await readTargets(searchText);
        const targetsCompleted = targets && targets.success;
        runInAction(() => {
            if (targetsCompleted) {
                this.state.targets.data = targets.data;
                this.state.targets.error = false;
            } else {
                this.state.targets.data = [];
                this.state.targets.error = true;
            }
        });
    };
}
