import React from 'react';
import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import ErrorBoundary from 'common/components/error/ErrorBoundary';

const Page = (props) => {
    return (
        <ErrorBoundary>
            <CssBaseline />
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    paddingLeft: 0.5,
                    paddingRight: 0.5,
                }}
            >
                {props.children}
            </Container>
        </ErrorBoundary>
    );
};

Page.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Page;
