import { insertItem, removeItem } from './arrayHelper';

export const updateOptions = (options, optionName) => {
    const index = options.indexOf(optionName);
    const updatedOptions =
        index > -1
            ? removeItem(options, { index })
            : insertItem(options, {
                  index,
                  item: optionName,
              });
    return updatedOptions;
};
