import baseRoutes from 'configuration/routing/baseRoutes';
import find from 'lodash-es/find';
import { action, observable, runInAction } from 'mobx';
import createAlarmIdsDataSource from 'scenes/main/common/helpers/alarmIdDataSourceHelper';
import { readSecurityUsers } from 'core/environment/environmentService';
import { redirect } from 'common/helpers/routerHelper';
import environmentUtils from 'common/utils/environmentUtils';
import { CreateType, TemplateType } from '../../helpers/enums';
import { requestNewReport } from './../requested/requestedService';
import getBuiltInTemplates, {
    getBlocksByCriteriaJson,
} from './builtInTemplatesProvider';
import {
    createCustomTemplatesDataSource,
    deleteTemplate,
    fetchCustomTemplatesList,
} from './templatesService';
import { createNewTemplate } from './templatesService';

const reportsRoute = `/${baseRoutes.main}/${baseRoutes.reports}`;

export default class ReportTemplatesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        isReady: false,
        hasError: false,
        fetchedData: null,
        deleteConfirmationVisible: false,
        deletedTemplate: null,
        create: {
            creatorFormVisible: false,
            type: null,
            id: null,
            alarmTypes: [],
            securityUsers: [],
            fields: [],
        },
    });

    fetchList = async () => {
        const customTemplates = await fetchCustomTemplatesList();
        const builtIn = getBuiltInTemplates();
        const customTemplatesDataSource = createCustomTemplatesDataSource(
            customTemplates.data,
            builtIn,
        );
        runInAction(() => {
            this.state.fetchedData = [
                ...customTemplatesDataSource,
                ...builtIn.data,
            ];
            this.state.isReady = true;
            this.state.hasError = false;
        });
    };

    get list() {
        if (!this.state.fetchedData) {
            return null;
        }
        // const searchText = this.rootStore.reportsStore.templatesFiltersStore.state.text;
        const options =
            this.rootStore.reportsStore.templatesFiltersStore.state.options;
        return this.state.fetchedData.filter(
            (item) => options.indexOf(item.type) > -1,
        );
    }

    showDeleteConfirmation = action((template) => {
        this.state.deleteConfirmationVisible = true;
        this.state.deletedTemplate = template;
    });

    confirmRemove = async () => {
        const templateId = this.state.deletedTemplate.id;
        const deleteResult = await deleteTemplate(templateId);
        if (deleteResult.success) {
            this.fetchList();
            this.rootStore.notificationsStore.showNotification(
                'Reports.TemplateRemoveSuccess',
            );
        } else {
            this.rootStore.notificationsStore.showNotification(
                'Reports.TemplateRemoveFailed',
            );
        }
        this.rejectRemove();
    };

    rejectRemove = action(() => {
        this.state.deleteConfirmationVisible = false;
        this.state.deletedTemplate = null;
    });

    onCreate = action((type, id) => {
        const template = this.getTemplate(id);
        const options = this.tryFetchOptions();
        if (!template || !options) {
            this.rootStore.notificationsStore.showNotification(
                'Common.GeneralError',
            );
            return;
        }
        this.state.create.template = template;
        this.state.create.creatorFormVisible = true;
        this.state.create.type = type;
        this.state.create.id = id;
    });

    onCloseCreate = action(() => {
        this.state.create.creatorFormVisible = false;
        this.state.create.template = null;
        this.state.create.type = null;
        this.state.create.id = null;
    });

    save = async (formValues) => {
        if (this.state.create.type === CreateType.Report) {
            const result = await requestNewReport(formValues);
            if (result.success) {
                this.onCloseCreate();
                redirect(reportsRoute);
            } else {
                this.rootStore.notificationsStore.showNotification(
                    'Reports.FailedToRequestReport',
                    formValues.title,
                );
            }
        }

        if (this.state.create.type === CreateType.Template) {
            const result = await createNewTemplate(formValues);
            if (result.success) {
                this.onCloseCreate();
                redirect(reportsRoute);
            } else {
                this.rootStore.notificationsStore.showNotification(
                    'Reports.FailedToCreateTemplate',
                    formValues.title,
                );
            }
        }
    };

    tryFetchOptions = async () => {
        const securityUsers = await readSecurityUsers();
        const alarmTypes = environmentUtils.getAlarmTypes();
        const fields = environmentUtils.getFields();
        const securityUsersCompleted = securityUsers && securityUsers.success;
        const alarmTypesAliases = fields[1].aliases;
        if (!securityUsersCompleted) {
            return false;
        } else {
            runInAction(() => {
                this.state.create.alarmTypes = alarmTypes.map((item) => {
                    const alarmType = {
                        value: item.value,
                        label: alarmTypesAliases[item.value] || item.value,
                    };
                    return alarmType;
                });
                this.state.create.securityUsers = securityUsers.data;
                this.state.create.fields = fields
                    .filter((x) => x.isVisibleInLog)
                    .map((item) => {
                        return {
                            value: item.id,
                            label: item.header,
                        };
                    });
                this.state.create.alarmIds = createAlarmIdsDataSource(fields);
            });
            return true;
        }
    };

    getTemplate = (id) => {
        const templates = this.state.fetchedData;
        if (!templates) {
            return null;
        }
        const isBuiltIn = isNaN(id);
        let template = null;
        if (isBuiltIn) {
            template = find(
                templates,
                (item) =>
                    item.reportClassId === id &&
                    item.type === TemplateType.BuiltIn,
            );
        } else {
            template = find(
                templates,
                (item) => item.id === id && item.type === TemplateType.Custom,
            );
        }
        if (template) {
            template.criteriaBlocks = getBlocksByCriteriaJson(
                template.criteriaJson,
            );
        }
        return template;
    };
}
