import { action, observable } from 'mobx';
import {
    getTheme,
    setTheme,
} from 'common/components/theme/modules/themeService';

export default class ThemeStore {
    constructor(rootStore, theme) {
        this.rootStore = rootStore;
        this.theme = theme;
    }

    state = observable({
        lightSelected: getTheme() || false,
    });

    changeTheme = action(() => {
        const theme = getTheme();
        setTheme(!theme);
        this.state.lightSelected = !this.state.lightSelected;
        if (this.state.lightSelected) {
            this.setLightTheme();
        } else {
            this.setDarkTheme();
        }
    });

    setDarkTheme() {
        var body = document.body;
        body.className = 'dark';
    }

    setLightTheme() {
        var body = document.body;
        body.className = 'light';
    }
}
