import WebsocketMessageBuilder from 'common/websocket/websocketMessageBuilder';
import { frameType } from '../../../common/helpers/webSockets/webSocketConsts';

const subscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType(frameType.REQUEST)
        .withId(888)
        .withName('subscribe')
        .withData({ stream: 'reports', topic: 'reports' })
        .build();
};

const unsubscribe = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType(frameType.REQUEST)
        .withId(889)
        .withName('unsubscribe')
        .withData({
            stream: 'reports',
        })
        .build();
};

const getActivations = () => {
    const messageBuilder = new WebsocketMessageBuilder();
    return messageBuilder
        .ofType(frameType.REQUEST)
        .withId(890)
        .withName('getReports')
        .withData(null)
        .build();
};

export default {
    subscribe: subscribe(),
    unsubscribe: unsubscribe(),
    getActivations: getActivations(),
};
