import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentSmartphones = React.lazy(() => import('./Smartphones'));

export default () => (
    <>
        <PageTitle pluginId="smartphones" pluginNameTag="Smartphones" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentSmartphones />
        </Suspense>
    </>
);
