import React, { Suspense } from 'react';
import PageTitle from 'common/components/navigation/containers/PageTitle';
import PageSpinner from 'common/components/spinner/PageSpinner';

const LoadableComponentActionTagsList = React.lazy(() =>
    import('./ActionTagsList'),
);

export default () => (
    <>
        <PageTitle pluginId="actionTags" pluginNameTag="ActionTags" />
        <Suspense fallback={<PageSpinner />}>
            <LoadableComponentActionTagsList />
        </Suspense>
    </>
);
