import { action, observable } from 'mobx';

export default class ActionTagsFiltersStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        text: '',
    });

    textChanged = action((value) => {
        this.state.text = value;
    });
}
