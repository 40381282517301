import { makeAutoObservable, runInAction, when } from 'mobx';
import { setLoggedInUserName } from 'core/environment/environmentService';
import { redirect } from 'common/helpers/routerHelper';
import environmentUtils from 'common/utils/environmentUtils';
import {
    authenticateWithPassword,
    createUserSession,
    logout,
    removeAuthenticationToken,
    setAuthenticationToken,
    setLoggedIn,
} from './loginService';

const allowedAuthenticationStates = {
    FullyAuthenticated: 'FullyAuthenticated',
    PreAuthenticated: 'PreAuthenticated',
};

export default class LoginStore {
    authenticationVisible = false;
    activeToken;
    activeAuthenticationType;
    username = '';
    secondFactorAuthenticationInProgress = false;

    _twoFactorAuthenticationReactionDisposer;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    createSession = async (formData) => {
        const username = formData.username;
        const result = await createUserSession(username);

        if (result.success) {
            this._handleCreateSessionSuccess({ username, ...result.data });
        } else {
            this._handleCreateSessionError(username);
        }
    };

    authenticateWithPassword = async (formData) => {
        const password = formData.password;
        const result = await authenticateWithPassword(password);

        result.success
            ? this._handleAuthenticateWithPasswordSuccess()
            : this._handleAuthenticateWithPasswordError();
    };

    logout = async () => {
        try {
            this.rootStore.websocketStore.close('Logout');
            this.rootStore.websocketStore.unsubscribeAll();
            await logout();
        } finally {
            this._setDefaultState();
            removeAuthenticationToken();
            setLoggedIn(false);
            setLoggedInUserName(null);
            this.rootStore.twoFactorAuthStore.setDefault();
        }
    };

    cancelAuthentication = () => {
        this._setDefaultState();
    };

    cancelTwoFactorAuthentication = () => {
        this.logout();
        this.rootStore.websocketStore.close(
            'Second factor authentication operation canceled.',
        );
    };

    _handleCreateSessionSuccess = ({
        username,
        session,
        authenticationType,
    }) => {
        this._setSessionData(username, session);
        this.username = username;
        this.activeToken = session;
        this.activeAuthenticationType = authenticationType;
        this.authenticationVisible = true;
    };

    _handleCreateSessionError = (username) => {
        const errorTag = username ? 'Login.UsernameError' : 'Login.Failed';
        this.rootStore.notificationsStore.showNotification(
            errorTag,
            null,
            'danger',
        );
        this.rootStore.environmentStore.setEnvDefaults();
        this._setDefaultState();
        setLoggedInUserName('');
    };

    _handleAuthenticateWithPasswordSuccess = () => {
        setLoggedIn(true);
        this._makeTwoFactorReactionHandler();
        this.rootStore.websocketStore.initialize();
    };

    _makeTwoFactorReactionHandler = async () => {
        await when(() => this.rootStore.websocketStore.state.connectionOpened);

        this._twoFactorAuthenticationReactionDisposer = when(
            () => this.rootStore.twoFactorAuthStore.state.status,
            this._handleTwoFactorAuthenticationCheck,
        );

        this.rootStore.twoFactorAuthStore.getAuthenticationState();
    };

    _handleTwoFactorAuthenticationCheck = async () => {
        this._twoFactorAuthenticationReactionDisposer();
        if (
            this.rootStore.twoFactorAuthStore.state.status ===
            allowedAuthenticationStates.FullyAuthenticated
        ) {
            await this.rootStore.environmentStore.loadClientConfigurationExternal();
            const mainRoute = environmentUtils.getMainRoute();
            runInAction(() => {
                this.authenticationVisible = false;
            });
            redirect(mainRoute);
            return;
        }
        this.rootStore.twoFactorAuthStore.initializeSecondFactorAuthentication();
        this.secondFactorAuthenticationInProgress = true;
    };

    _handleAuthenticateWithPasswordError = () => {
        setLoggedIn(false);
        this.rootStore.notificationsStore.showNotification(
            'Login.Failed',
            null,
            'danger',
        );
    };

    _setSessionData = (username, session) => {
        setAuthenticationToken(session);
        setLoggedInUserName(username);
        this.rootStore.environmentStore.setUsername(username);
    };

    _setDefaultState = () => {
        this.authenticationVisible = false;
        this.activeToken = null;
        this.activeAuthenticationType = null;
        this.username = '';
        this.secondFactorAuthenticationInProgress = false;
    };
}
