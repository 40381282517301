import React, { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { optionalNode } from 'common/helpers/commonPropTypes';

const styles = {
    root: {
        maxHeight: '240px',
        overflow: 'auto',
    },
    checkboxRoot: {
        height: 'auto',
        width: 'auto',
        padding: 0,
    },
};

const MultiSelector = ({
    options,
    value,
    onChange,
    name,
    customSearch,
    classes,
}) => {
    let selection = value;

    const handleSelection = (item) => {
        const exists = selection.some((selected) => selected.id === item.id);
        let updatedSelection;
        if (exists) {
            updatedSelection = selection.filter(
                (selected) => selected.id !== item.id,
            );
        } else {
            updatedSelection = [...selection, item];
        }
        onChange(name, updatedSelection, item);
    };

    const isChecked = (item) => {
        return selection.some((selected) => selected.id === item.id);
    };

    return (
        <Fragment>
            {customSearch}
            <List className={classes.root}>
                {options.map((item) => {
                    const { header, id } = item;
                    return (
                        <ListItem
                            button
                            dense
                            disableGutters
                            key={id}
                            onClick={() => handleSelection(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    className={classes.checkboxRoot}
                                    checked={isChecked(item)}
                                />
                            </ListItemIcon>
                            <ListItemText primary={header} />
                        </ListItem>
                    );
                })}
            </List>
        </Fragment>
    );
};

MultiSelector.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    name: PropTypes.string.isRequired,
    classes: PropTypes.object,
    withDefaultSearch: PropTypes.bool,
    customSearch: optionalNode,
};

export default withStyles(styles)(MultiSelector);
