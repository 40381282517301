import { DeviceStatus } from './enums';

export const translateDeviceStatusToCssClass = (type) => {
    switch (type) {
        case DeviceStatus.NoIssues:
            return 'primary';
        case DeviceStatus.Warning:
            return 'warning';
        case DeviceStatus.Danger:
            return 'danger';
        default:
            return 'primary';
    }
};

export const computeStatus = (isBatteryOk, isMissing) => {
    return isBatteryOk
        ? isMissing
            ? DeviceStatus.Warning
            : DeviceStatus.NoIssues
        : DeviceStatus.Danger;
};
