import ActionTagsFiltersStore from './modules/list/actionTagsFiltersStore';
import ActionTagsListStore from './modules/list/actionTagsListStore';
import ActionTagsAddStore from './modules/save/actionTagsAddStore';
import ActionTagsUpdateStore from './modules/save/actionTagsUpdateStore';

export default class ActionTagsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.actionTagsListStore = new ActionTagsListStore(rootStore);
        this.actionTagsFiltersStore = new ActionTagsFiltersStore(rootStore);
        this.actionTagsUpdateStore = new ActionTagsUpdateStore(rootStore);
        this.actionTagsAddStore = new ActionTagsAddStore(rootStore);
    }
}
