import { makeAutoObservable } from 'mobx';
import getItems from '../helpers/menuDataSourceProvider';

export default class NavigationStore {
    menuVisible = false;
    menuItems = [];
    currentPluginName = '';

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    initializeMenuItems = () => {
        this.menuItems = getItems();
    };

    toggleMenu = () => {
        this.menuVisible = !this.menuVisible;
    };

    setMenuVisibility = (value) => {
        this.menuVisible = !!value;
    };

    setCurrentPluginName = (pluginId, pluginLocalizedName) => {
        const matchingItem = this.menuItems.find((x) => x.id === pluginId);
        const configurableName = matchingItem?.configurableName;
        this.currentPluginName =
            configurableName || pluginLocalizedName || 'LAS WebViewer';
    };
}
