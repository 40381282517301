import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import Checkbox from 'common/components/form/controls/Checkbox';
import Input from 'common/components/form/controls/Input';
import Radio from 'common/components/form/controls/Radio';
import { SliceRangeMode, TemplateType } from '../../../helpers/enums';

const SliceRange = ({ locale, ...props }) => {
    const formValues = props.formProps.values;
    const isLastNSlicesEnabled =
        get(formValues, 's.mode') === SliceRangeMode.LastNSlices;
    const isAbsoluteAvailable = props.type === TemplateType.Custom;
    const isAbsoluteEnabled =
        get(formValues, 's.mode') === SliceRangeMode.Absolute;
    const locales = locale.Reports;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">{locales.DateRange}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Field
                    value={SliceRangeMode.LastNSlices}
                    component={Radio}
                    name="s.mode"
                    label={locales.LastDays}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="s.slices"
                    type="number"
                    component={Input}
                    disabled={!isLastNSlicesEnabled}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="s.current"
                    type="number"
                    component={Checkbox}
                    label={locales.IncludingToday}
                    disabled={!isLastNSlicesEnabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    value={SliceRangeMode.Absolute}
                    component={Radio}
                    name="s.mode"
                    label={locales.From}
                    disabled={!isAbsoluteAvailable}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="s.min"
                    type="datetime"
                    component={Input}
                    label={locales.From}
                    disabled={!isAbsoluteEnabled}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="s.max"
                    type="datetime"
                    component={Input}
                    label={locales.To}
                    disabled={!isAbsoluteEnabled}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    value={SliceRangeMode.LastMonth}
                    component={Radio}
                    name="s.mode"
                    label={locales.PreviousMonth}
                />
            </Grid>
        </Grid>
    );
};

SliceRange.propTypes = {
    formProps: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
    type: PropTypes.string,
};

const exported = {
    component: SliceRange,
    key: 'sliceRange',
};

export default exported;
