import { isNotificationValidMessage } from './notificationVerifier';

export const processNotification = (
    notification,
    currentDataState,
    isActivated = true,
) => {
    if (!isNotificationValidMessage(notification)) {
        return currentDataState;
    }
    const notificationData = notification[2].data;
    if (!notificationData) {
        console.warn('Notifcation data not found. Returning current state.');
        return currentDataState;
    }
    const { changeType, logIndex } = notificationData;
    if (!changeType || !logIndex) {
        console.warn(
            'Notification data is not complete. Returning current state.',
        );
        return currentDataState;
    }

    const commandToRun = commands[changeType];
    if (commandToRun) {
        return commandToRun(currentDataState, notificationData, isActivated);
    }
    return currentDataState;
};

const commands = {
    reset: (currentDataState, notificationData, isActivated) =>
        reset(currentDataState, notificationData, isActivated),
    add: (currentDataState, notificationData) =>
        add(currentDataState, notificationData),
    update: (currentDataState, notificationData) =>
        update(currentDataState, notificationData),
    remove: (currentDataState, notificationData) =>
        remove(currentDataState, notificationData),
};

const reset = (currentDataState, notificationData, isActivated) => {
    return isActivated
        ? [
              ...notificationData.newItems.map((item) => {
                  return { ...item, logIndex: notificationData.logIndex };
              }),
          ]
        : currentDataState;
};

const add = (currentDataState, notificationData) => {
    return [
        ...currentDataState,
        ...notificationData.newItems.map((item) => {
            return { ...item, logIndex: notificationData.logIndex };
        }),
    ];
};

const remove = (currentDataState, notificationData) => {
    return currentDataState.filter(
        (item) => notificationData.removedItemIds.indexOf(item.id) < 0,
    );
};

const update = (currentDataState, notificationData) => {
    const newItems = notificationData.newItems;
    const newItemsIds = newItems.map((item) => item.id);
    const listWithoutUpdated = currentDataState.filter(
        (item) => newItemsIds.indexOf(item.id) === -1,
    );
    return [...listWithoutUpdated, ...newItems];
};
