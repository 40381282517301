import { action, makeObservable, observable } from 'mobx';

class NotificationsStore {
    visible = false;
    type;
    localeTag;
    keyValue;

    constructor() {
        makeObservable(this, {
            visible: observable,
            hideNotification: action,
            showNotification: action,
        });
    }

    hideNotification = () => {
        clearTimeout(this.timeout);
        this.visible = false;
        this.localeTag = null;
        this.keyValue = null;
        this.type = '';
    };

    showNotification = (localeTag, keyValue, type = 'success') => {
        this.hideNotification();
        this.visible = true;
        this.localeTag = localeTag;
        this.keyValue = keyValue;
        this.type = type;
        this.timeout = setTimeout(() => {
            this.hideNotification();
        }, 3000);
    };
}

export default NotificationsStore;
