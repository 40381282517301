import { observable, runInAction, when } from 'mobx';
import toHex from './../../common/helpers/alarmColorParser';
import messages from './activeAlarmsMessages';
import {
    getActivationsData,
    isSuccessfulActivationResponse,
    isSuccessfulSubscriptionResponse,
    mergeAlarmsBufferedDataWithActivations,
    processAlarmNotification,
    setActivationAccepted,
    setActivationClosed,
    setActivationRejected,
} from './activeAlarmsService';

export default class ActiveAlarmsStore {
    websocketSubscriberName = 'active-alarms';

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        isSubscribed: false,
        isActivated: false,
        rawData: [],
        data: [],
    });

    subscribe = () => {
        when(
            () => this.rootStore.websocketStore.state.connectionOpened,
            this.createMessageHandler,
        );
    };

    createMessageHandler = () => {
        this.rootStore.websocketStore.subscribe({
            name: this.websocketSubscriberName,
            callback: (message) => {
                const isSubscriptionResponse =
                    isSuccessfulSubscriptionResponse(message);
                if (isSubscriptionResponse) {
                    runInAction(() => {
                        this.state.isSubscribed = true;
                    });
                    this.rootStore.websocketStore.send(messages.getActivations);
                    return;
                }

                if (!this.state.isSubscribed) {
                    return;
                }

                const isActivationResponse =
                    isSuccessfulActivationResponse(message);
                if (isActivationResponse) {
                    const activationsData = getActivationsData(message);
                    const mergeResult = mergeAlarmsBufferedDataWithActivations(
                        this.state.rawData,
                        activationsData,
                    );
                    const data = this.dataCreator(mergeResult);
                    runInAction(() => {
                        this.state.isActivated = true;
                        this.state.rawData = mergeResult;
                        this.state.data = data;
                    });
                } else {
                    const rawData = processAlarmNotification(
                        message,
                        this.state.rawData,
                        this.state.isActivated,
                    );
                    const data = this.dataCreator(rawData);
                    if (rawData) {
                        runInAction(() => {
                            this.state.rawData = rawData;
                            this.state.data = data;
                        });
                    }
                }
            },
        });
        this.rootStore.websocketStore.send(messages.subscribe);
    };

    unsubscribe = () => {
        this.rootStore.websocketStore.send(messages.unsubscribe);
        this.rootStore.websocketStore.unsubscribe(this.websocketSubscriberName);
        runInAction(() => {
            this.state.isSubscribed = false;
            this.state.isActivated = false;
            this.state.rawData = [];
            this.state.data = [];
        });
    };

    acceptActivation = async (id) => {
        const result = await setActivationAccepted(id);
        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActiveAlarms.FailedToAcceptActivation',
            );
        }
    };

    rejectActivation = async (id) => {
        const result = await setActivationRejected(id);
        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActiveAlarms.FailedToRejectActivation',
            );
        }
    };

    closeActivation = async (id) => {
        const result = await setActivationClosed(id);
        if (!result.success) {
            this.rootStore.notificationsStore.showNotification(
                'ActiveAlarms.FailedToCloseActivation',
            );
        }
    };

    dataCreator = (rawData) => {
        return rawData
            .sort((a, b) => b.priority - a.priority)
            .map((item) => {
                return {
                    id: item.id,
                    alarmIdValue:
                        item.alarmIdAlias == null
                            ? item.alarmIdValue
                            : item.alarmIdAlias,
                    alarmTypeValue:
                        item.alarmTypeAlias == null
                            ? item.alarmTypeValue
                            : item.alarmTypeAlias,
                    color: toHex(item.color),
                    alarmTypeValueRaw: item.alarmTypeValue,
                };
            });
    };
}
