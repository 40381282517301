import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field, useFormikContext } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import Input from 'common/components/form/controls/Input';

const DEFAULT_DURATION = '00:00:00';

const AlarmActivationDuration = ({ locale }) => {
    const fieldName = 'dur.min';
    const { values, setFieldValue } = useFormikContext();

    const setDefault = () => {
        const fieldValue = get(values, fieldName);
        if (!fieldValue) {
            setFieldValue(fieldName, DEFAULT_DURATION);
        }
    };

    useEffect(() => {
        setDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBlur = () => {
        setDefault();
    };

    const locales = locale.Reports;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {locales.AlarmActivationDuration}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {locales.ShowOnlyAlarmsThatWereActiveForAtLeast}:
                </Typography>
                <Field
                    name="dur.min"
                    type="text"
                    placeholder={DEFAULT_DURATION}
                    component={Input}
                    onBlur={handleBlur}
                />
            </Grid>
        </Grid>
    );
};

AlarmActivationDuration.propTypes = {
    locale: PropTypes.object.isRequired,
};

const exported = {
    component: AlarmActivationDuration,
    key: 'alarmActivationDuration',
};

export default exported;
