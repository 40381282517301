export const requestId = {
    KEEP_ALIVE: 13,
};

export const frameType = {
    REQUEST: 'request',
    RESPONSE: 'response',
    NOTIFICATION: 'notification',
};

export const subscription = 'subscription';
