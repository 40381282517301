import { action, observable, runInAction } from 'mobx';
import {
    getLoggedInUserName,
    loadClientConfiguration,
    readAlarmTypes,
    readFields,
} from 'core/environment/environmentService';
import environmentUtils from 'common/utils/environmentUtils';

class EnvironmentStore {
    state = observable({
        loggedUsername: '',
        currentLocation: '',
        loggedOutDueToInacvtivity: false,
        environmentLoaded: false,
    });

    setUsername = action((username) => {
        this.state.loggedUsername = username;
    });

    setCurrentLocation = action((location) => {
        this.state.currentLocation = location;
    });

    setEnvDefaults = action(() => {
        this.state.currentLocation = '';
        this.state.loggedUsername = '';
        this.state.loggedOutDueToInacvtivity = false;
    });

    getLoggedUsername = () => {
        const loggedInUserName = getLoggedInUserName();
        runInAction(() => {
            this.state.loggedUsername = loggedInUserName;
        });
        return loggedInUserName;
    };

    loadEnvironment = async () => {
        runInAction(() => {
            this.state.environmentLoaded = false;
        });

        try {
            const [
                clientConfigurationResponse,
                fieldsResponse,
                alarmTypesResponse,
            ] = await Promise.all([
                loadClientConfiguration(),
                readFields(),
                readAlarmTypes(),
            ]);

            if (
                fieldsResponse &&
                fieldsResponse.success &&
                alarmTypesResponse &&
                alarmTypesResponse.success
            ) {
                environmentUtils.setClientConfiguration(
                    clientConfigurationResponse,
                );
                environmentUtils.setFields(fieldsResponse.data);
                environmentUtils.setAlarmTypes(alarmTypesResponse.data);
            }

            runInAction(() => {
                this.state.environmentLoaded = true;
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    loadClientConfigurationExternal = async () => {
        const clientConfigurationResponse = await loadClientConfiguration();
        environmentUtils.setClientConfiguration(clientConfigurationResponse);
    };
}

export default EnvironmentStore;
