import { get } from 'core/communcation/api';
import { readJson } from 'core/communcation/externalResourcesReader';

const SESSION_LOGGED_IN_NAME = 'X-LAS-LoggedInName';
const SESSION_FULL_AUTH_NAME = 'X-LAS-FA';

export const readFields = () => {
    const fieldsEndpoint = 'alarmenv/fields';
    return get(fieldsEndpoint);
};

export const readAlarmTypes = () => {
    const alarmTypesEndpoint = 'alarmenv/alarmtypes';
    return get(alarmTypesEndpoint);
};

export const readSecurityUsers = () => {
    const securityUsersEndpoint = 'reporting/securityusers';
    return get(securityUsersEndpoint);
};

export const readTargets = (searchString = '') => {
    const targetsEndpoint = `reporting/targets?s=${searchString}`;
    return get(targetsEndpoint);
};

export const setLoggedInUserName = (value) => {
    sessionStorage.setItem(SESSION_LOGGED_IN_NAME, value);
};

export const getLoggedInUserName = () => {
    return sessionStorage.getItem(SESSION_LOGGED_IN_NAME);
};

export const setFullAuthentication = (value) => {
    sessionStorage.setItem(SESSION_FULL_AUTH_NAME, value);
};

export const getFullAuthentication = () => {
    const item = sessionStorage.getItem(SESSION_FULL_AUTH_NAME);
    return item && item === 'true';
};

export const removeFullAuthenticationMarker = () => {
    sessionStorage.removeItem(SESSION_FULL_AUTH_NAME);
};

export const loadClientConfiguration = () => {
    return readJson(`/config.json?t=${Date.now()}`);
};
